@charset "UTF-8";
/*-----------------------------------------------------------------------------------

-----------------------------------------------------------------------------------
    
    >>> TABLE OF CONTENTS:
    =======================

    01. Common/Reusable CSS
        1.1 - Utility CSS
        1.2 - Section Padding
        1.3 - Template Button 
        1.4 - Section Title
        1.5 - Search Modal Form
        1.6 - Custom Animation 
        1.7 - Preloader
        1.8 - Scroll To Top
        1.9 - List Style
        1.10 - List Style V2
        1.11 - List Style V3
        1.12 - Header CSS
        1.13 - Menu Active Overlay
        1.14 - Header OffCanvas Menu
        1.15 - Sticky Menu
        1.16 - Language Selection Dropdown
        1.17 - Header Navigation V2
        1.18 - Header Navigation V3
        1.19 - Header Navigation V4
        1.20 - Header Navigation V5
        1.21 - Header Navigation V6
        1.22 - Breadcrumb Area 
        1.23 - Particle Effect 
        1.24 - Particle Effect V2
        1.25 - Particle Effect V3
        1.26 - Particle Effect V4
    02. App Feature Area 
        2.1 - Single Service Box
        2.2 - Single Service Box V2
        2.3 - Single Service Box V3
    03. Data Analysis Feature Area 
    04. What We Do / Services Area
        4.1 - Single Feature Box
        4.2 - Single Feature Box V2
        4.3 - Single Feature Box V2 Border Effect 
        4.4 - Single Feature Box V3
    05. App Statistic Area
        5.1 - Company Statistic Area
    06. Testimonial Area
        6.1 - Single Testimonial
        6.2 - Single Testimonial V2
        6.3 - Single Testimonial V3
        6.4 - Single Testimonial V4
        6.5 - Single Testimonial V5
        6.6 - Single Testimonial V6
        6.7 - Testimonial V5 Right Image
        6.8 - Testimonial Custom Rating
        6.9 - Testimonial Sliders Arrows
        6.10 - Testimonial Sliders Dots
        6.11 - Testimonial Slider V2 Slick Dots
    07. Counter Up Area
        7.1 - Single Counter Up Box
        7.2 - Counter Up Box V2
        7.3 - Counter Up Box
        7.4 - Fact and CTA Area
        7.5 - Simple Counter Up
    08. Call to Action Area
        8.1 - Call to Action V2
        8.2 - Simple CTA Area
        8.3 - App CTA Area
        8.4 - Video and CTA Area
    09. Hero Area 
        9.1 - Hero Area V1
        9.2 - Hero Area V2
        9.3 - Hero Area V3
        9.4 - Hero Area V4
        9.5 - Hero Area V5
        9.6 - Hero Area V6
    10. Brands Slider
        10.1 - Brands Slider V2
    11. Team Member Area
        11.1 - Single Team Member 
        11.2 - Single Team Member V2
    12. Newsletter Search Area 
        12.1 - Newsletter Form 
        12.2 - Newsletter Form V2
        12.3 - Newsletter Search Area V2 
        12.4 - Subscribe Newsletter 
    13. FAQ Area 
        13.1 - FAQ Accordion 
        13.2 - FAQ Accordion V2 
    14. Pricing Table
        14.1 - Pricing Table V2
    15. Blog and News Area
        15.1 - Blog and News V2
        15.2 - Blog Post Item V2
        15.3 - Blog Post Item V4
        15.4 - Blog Post Item V5
    16. Features Area 
        16.1 - Single Iconic Box 
        16.2 - Iconic Box V2
        16.3 - Iconic Box V3
        16.4 - Iconic Box V4
        16.5 - Iconic Box V5
        16.6 - Simple Icon Box
        16.7 - Simple Icon Box V2
    17. Manage Data Area Iconic List
        17.1 - Single Iconic List 
        17.2 - Iconic list V2
        17.3 - Why Choose Us Area (Iconic List)
    18. Collaboration Area
        18.1 - Collaboration Feature Preview
        18.2 - Collaboration Area V2
    19. UI Design Feature Area
    20. Manage Data Feature Area
    21. Featured Video
    22. About Us Area
        22.1 - Why Choose Us Area
    23. Team Skills Area 
        23.1 - Team Skills Bar 
    24. Contact Page 
        24.1 - Contact Info Box
        24.2 - Contact Box
        24.3 - Single Info Box
        24.4 - Our Services Area V3
        24.5 - Services Law Area
        24.6 - Contact Form Area
        24.7 - Contact Form Area V2
        24.8 - Contact Page Maps
    25. 404 Not Found Area 
    26. Coming Soon Page
    27. Service Details Page
    28. Blog Standard Page
    29. Blog Details Page 
        29.1 - Blog Details Comments Area
        29.2 - Blog Details Comments Form
        29.3 - Blog Posts Sidebar
        29.4 - Blog Posts Pagination 
    30. Footer Area 
        30.1 - Footer Area V2
        30.2 - Footer Area V3
        30.2 - Footer Area V4
    
-----------------------------------------------------------------------------------*/

/* 01. Common/Reusable CSS */

@font-face {
    font-family: 'CircularStdBold';
    src: url('../fonts/circularstd/CircularStdBold.eot');
    src: url('../fonts/circularstd/CircularStdBold.eot') format('embedded-opentype'), url('../fonts/circularstd/CircularStdBold.woff2') format('woff2'), url('../fonts/circularstd/CircularStdBold.woff') format('woff'), url('../fonts/circularstd/CircularStdBold.ttf') format('truetype'), url('../fonts/circularstd/CircularStdBold.svg#CircularStdBold') format('svg');
    font-display: swap;

}
@font-face {
    font-family: 'CircularStdMedium';
    src: url('../fonts/circularstd/CircularStdMedium.eot');
    src: url('../fonts/circularstd/CircularStdMedium.eot') format('embedded-opentype'), url('../fonts/circularstd/CircularStdMedium.woff2') format('woff2'), url('../fonts/circularstd/CircularStdMedium.woff') format('woff'), url('../fonts/circularstd/CircularStdMedium.ttf') format('truetype'), url('../fonts/circularstd/CircularStdMedium.svg#CircularStdMedium') format('svg');
	font-display: swap;
}
@font-face {
    font-family: 'CircularStdBook';
    src: url('../fonts/circularstd/CircularStdBook.eot');
    src: url('../fonts/circularstd/CircularStdBook.eot') format('embedded-opentype'), url('../fonts/circularstd/CircularStdBook.woff2') format('woff2'), url('../fonts/circularstd/CircularStdBook.woff') format('woff'), url('../fonts/circularstd/CircularStdBook.ttf') format('truetype'), url('../fonts/circularstd/CircularStdBook.svg#CircularStdBook') format('svg');
	font-display: swap;
} 
body {
    font-family: 'CircularStdBook';
    font-size: 17px;
    color: #36234b;
    line-height: 30px;
}
h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-family: 'CircularStdBold';
    font-weight: 700;
}
h1 {
    font-size: 65px;
    line-height: 80px;
}
h2{
    font-size: 42px;
    line-height: 50px;
}
h3 {
    font-size: 30px;
    line-height: 38px;
}
h4{
    font-size: 24px;
    line-height: 32px;
}
h5 {
    font-size: 20px;
    line-height: 28px;
}
h6 {
    font-size: 16px;
    line-height: 24px;
}
p {
    margin: 0px;
    padding: 0px;
}
img {
    max-width: 100%;
    height: auto;
}
ul, ol {
    list-style: none;
    padding: 0;
    margin: 0;
}
a {
    color: inherit;
    text-decoration: none;
    -webkit-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}
a:hover, a:focus {
    text-decoration: none;
}
a:focus, input:focus, textarea:focus, button:focus, .form-control:focus {
    text-decoration: none;
    outline: none;
    -webkit-box-shadow: none;
            box-shadow: none;
}
i, span, a {
    display: inline-block;
}
button {
    border: none;
}

/* Utility CSS */
.bg-ocean-blue {
    background-color: #0040e5;
}
.bg-clear-blue {
    background-color: #3377FF;
}
.bg-royal-blue {
    background-color: #473BF0;
}
.bg-burning-orange{
    background-color: #F4732A;
}
.bg-magnolia {
    background-color: #F2F5FE;
}
.bg-light-magnolia {
    background-color: #F1F5FF;
}
.bg-water {
    background-color: #EDF2FD;
}
.bg-tuna {
    background-color: #36234B;
}
.bg-alice-blue {
    background-color: #F0F6FF;
}
.bg-solid-dark {
    background-color: #1B202F;
}
.bg-dark-black{
    background-color: #161C2D;
}
.bg-dark-blue {
    background-color: #2B354F;
}
.bg-dark-black-v2 {
    background-color: #1B202F;
}
.bg-white-lilac {
    background-color: #F4F7FA;
}
.bg-contain {
    background-size: contain;
    background-position: top center;
    background-attachment: scroll;
    background-repeat: no-repeat;
}
.bg-cover{
    background-size: cover;
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
}
.border-top{
    border-top: 1px solid #EDF2FD;
}
.border-top-blue{
    border-top: 1px solid rgb(71 59 240 / 10%);
}
.border-bottom-purple{
    border-bottom: 1px solid rgb(130 8 254 / 12%);
}
.section-gradient-1 {
    background-image: linear-gradient( -38deg, rgb(0,64,229) 0%, rgb(2,140,235) 59%, rgb(4,215,241) 100%);
    background-image: -webkit-linear-gradient( -38deg, rgb(0,64,229) 0%, rgb(2,140,235) 59%, rgb(4,215,241) 100%);
    background-image: -ms-linear-gradient( -38deg, rgb(0,64,229) 0%, rgb(2,140,235) 59%, rgb(4,215,241) 100%);
}
.position-relative{
    position: relative;
    z-index: 1;
}
.content-left-spacer {
    padding-left: 70px;
}
.content-right-spacer{
    padding-right: 70px;
}

/* Section Padding */
.section-padding-top {
    padding-top: 130px;
}
.section-padding-bottom {
    padding-bottom: 130px;
}
.section-padding-bottom-2{
    padding-bottom: 80px;
}
.section-padding-2 {
    padding: 85px 0;
}
.section-padding{
    padding: 130px 0;
}
/* Template Button */
.filled-btn, .filled-btn.btn-bordered {
    display: inline-block;
    background: #473BF0;
    color: #fff;
    padding: 12px 40px;
    font-size: 15px;
    border-radius: 50px;
    font-family: 'CircularStdBold';
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
.filled-btn i, .filled-btn.btn-bordered i {
    margin-left: 8px;
    font-size: 15px;
}
.filled-btn, .filled-btn.btn-bordered {
    position: relative;
    z-index: 1;
    overflow: hidden;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(71, 59, 240, 0.36);
    box-shadow: 0px 10px 30px 0px rgba(71, 59, 240, 0.36);
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    text-transform: capitalize;
    vertical-align: middle;
}
.filled-btn.button-radius,
.filled-btn.button-radius:after,
.filled-btn.btn-bordered.button-radius,
.filled-btn.btn-bordered.button-radius:after{
    border-radius: 7px;
}
.filled-btn:after, .filled-btn.btn-bordered:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 0;
    top: 0;
    left: 0;
    background-color: #161c2d;
    z-index: -1;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    border-radius: 50px;
}
.filled-btn.btn-bordered {
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #161c2d;
    border: 2px solid #D1D1F8;
    padding: 10px 40px;
    -webkit-transition-delay: 0.1s;
    -o-transition-delay: 0.1s;
    transition-delay: 0.1s;
}
.filled-btn.btn-bordered {
    background: transparent;
    border: 2px solid #0040E5;
    color: #0040E5;
}
.filled-btn:hover:after, .filled-btn.btn-bordered:hover:after {
    width: 100%;
}
.filled-btn:hover {
    -webkit-box-shadow: 0 0 30px 0px rgb(0 0 0 / 30%);
    box-shadow: 0 0 30px 0px rgb(0 0 0 / 30%);
    color: #fff;
}
.filled-btn.btn-bordered:hover {
    color: #fff;
    background: #161c2d;
}
.filled-btn.btn-bordered:hover {
    border: 2px solid #161c2d;
}
.filled-btn.filled-bg-white{
    background-color: #ffffff;
    -webkit-box-shadow: none;
            box-shadow: none;
    color: #161c2d;
}
.filled-btn.filled-bg-white:hover {
    background-color: #161c2d;
    -webkit-transition-delay: 0.1s;
         -o-transition-delay: 0.1s;
            transition-delay: 0.1s;
}
.filled-btn.filled-bg-white:hover{
    color: #fff;
}
.filled-btn.bg-rose-bud{
    background-color: #F6B9A7;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(246, 185, 167, 0.61);
            box-shadow: 0px 10px 30px 0px rgba(246, 185, 167, 0.61);
    color: #36234b;
}
.filled-btn.bg-rose-bud:hover {
    background: #161c2d;
    -webkit-transition-delay: 0.1s;
         -o-transition-delay: 0.1s;
            transition-delay: 0.1s;
    color: #fff;
}
.filled-btn.bg-mandy{
    background-color: #E25C6A;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(226, 92, 106, 0.36);
            box-shadow: 0px 10px 30px 0px rgba(226, 92, 106, 0.36);
    color: #fff;
}
.filled-btn.bg-purple{
    background-color: #8208FE;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(130, 8, 254, 0.36);
            box-shadow: 0px 10px 30px 0px rgba(130, 8, 254, 0.36);
    color: #fff;
}
.filled-btn.bg-mandy:after,
.filled-btn.bg-purple:after,
.filled-btn.bg-blue-chalk.button-radius:after{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #161c2d;
    content: "";
    border-radius: 7px;
    -webkit-transform: perspective(200px) scaleX(0.1) rotateX(90deg) translateZ(-10px);
            transform: perspective(200px) scaleX(0.1) rotateX(90deg) translateZ(-10px);
    -webkit-transform-origin: bottom center;
        -ms-transform-origin: bottom center;
            transform-origin: bottom center;
    -webkit-transition: -webkit-transform 0.4s linear, -webkit-transform 0.4s linear;
    transition: -webkit-transform 0.4s linear, -webkit-transform 0.4s linear;
    -o-transition: transform 0.4s linear, transform 0.4s linear;
    transition: transform 0.4s linear, transform 0.4s linear;
    transition: transform 0.4s linear, transform 0.4s linear, -webkit-transform 0.4s linear, -webkit-transform 0.4s linear;
    z-index: -1;
}
.filled-btn.bg-mandy:hover:after,
.filled-btn.bg-purple:hover:after,
.filled-btn.bg-blue-chalk.button-radius:hover:after{
    -webkit-transform: perspective(200px) scaleX(1.05) rotateX(0deg) translateZ(0);
            transform: perspective(200px) scaleX(1.05) rotateX(0deg) translateZ(0);
    -webkit-transition: -webkit-transform 0.4s linear, -webkit-transform 0.4s linear;
    transition: -webkit-transform 0.4s linear, -webkit-transform 0.4s linear;
    -o-transition: transform 0.4s linear, transform 0.4s linear;
    transition: transform 0.4s linear, transform 0.4s linear;
    transition: transform 0.4s linear, transform 0.4s linear, -webkit-transform 0.4s linear, -webkit-transform 0.4s linear;
}
.filled-btn.bg-tuna{
    background-color: #36234B;
    color: #fff;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(54, 35, 75, 0.36);
            box-shadow: 0px 10px 30px 0px rgba(54, 35, 75, 0.36);
}
.filled-btn.bg-ocean-blue{
    background-color: #0040e5;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 64, 229, 0.36);
            box-shadow: 0px 10px 30px 0px rgba(0, 64, 229, 0.36);
}
.filled-btn.bg-ocean-blue:hover {
    -webkit-box-shadow: 0 0 30px 0px rgb(0 0 0 / 30%);
    box-shadow: 0 0 30px 0px rgb(0 0 0 / 30%);
}
.filled-btn.bg-magenta{
    background-color: #ED00CE;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(237, 0, 206, 0.36);
            box-shadow: 0px 10px 30px 0px rgba(237, 0, 206, 0.36);
}
.filled-btn.bg-magenta:hover, 
.filled-btn.bg-burning-orange:hover,
.filled-btn.bg-rose-bud:hover,
.filled-btn.bg-mandy:hover,
.filled-btn.bg-purple:hover{
    -webkit-box-shadow: 0px 10px 30px 0px rgb(22 28 45 / 30%);
            box-shadow: 0px 10px 30px 0px rgb(22 28 45 / 30%);
}
.filled-btn.bg-ocean-blue-gradient{
    background-image: linear-gradient( -38deg, rgb(0,64,229) 0%, rgb(2,140,235) 59%, rgb(4,215,241) 100%);
    background-image: -webkit-linear-gradient( -38deg, rgb(0,64,229) 0%, rgb(2,140,235) 59%, rgb(4,215,241) 100%);
    background-image: -ms-linear-gradient( -38deg, rgb(0,64,229) 0%, rgb(2,140,235) 59%, rgb(4,215,241) 100%);
    -webkit-box-shadow: 0px 7px 25px 0px rgba(0, 64, 229, 0.4);
            box-shadow: 0px 7px 25px 0px rgba(0, 64, 229, 0.4);
}
.filled-btn.bg-ocean-blue-gradient:after{
    background-color: transparent;
    background-image: linear-gradient( -38deg, rgb(4,215,241) 0%, rgb(2,140,235) 59%, rgb(0,64,229) 100%);
    background-image: -webkit-linear-gradient( -38deg, rgb(4,215,241) 0%, rgb(2,140,235) 59%, rgb(0,64,229) 100%);
    background-image: -ms-linear-gradient( -38deg, rgb(4,215,241) 0%, rgb(2,140,235) 59%, rgb(0,64,229) 100%);
    box-shadow: 0px 7px 25px 0px rgb(0 64 229 / 40%);
}
.filled-btn.bg-ocean-blue-gradient:hover {
    background: #fff;
    -webkit-transition-delay: 0.2s;
         -o-transition-delay: 0.2s;
            transition-delay: 0.2s;
}
.filled-btn.bg-burning-orange{
    background-color: #F4732A;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(244, 115, 42, 0.36);
            box-shadow: 0px 10px 30px 0px rgba(244, 115, 42, 0.36); 
}
.filled-btn.bg-blue-chalk{
    background-color: #E5ECFC;
    color: #1a1b1e;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(236, 235, 253, 0.36);
            box-shadow: 0px 10px 30px 0px rgba(236, 235, 253, 0.36); 
}
.filled-btn.bg-blue-chalk:hover{
    color: #fff;
}
.filled-btn.btn-bordered.bg-royal-blue{
    border-color: rgb(71 59 240 / 10%);
    color: #161c2d;
}
.filled-btn.btn-bordered.bg-royal-blue:hover,
.filled-btn.btn-bordered.bg-clear-blue:hover{
    color: #fff;
    -webkit-transition-delay: 0.1s;
         -o-transition-delay: 0.1s;
            transition-delay: 0.1s;
}
.filled-btn.btn-bordered.bg-clear-blue{
    background-color: transparent;
    border-color: rgb(51 119 255 / 10%);
    color: #161c2d;
    box-shadow: none;
}
.filled-btn.btn-bordered.bg-clear-blue:hover {
    border: 2px solid #161c2d;
    background-color: #161c2d;
}
.filled-btn.btn-bordered.btn-off-white {
    border: 2px solid #FFFFFF;
    color: #fff;
}
.filled-btn.btn-bordered.btn-off-white:hover {
    box-shadow: none;
    background-color: #fff;
    border-color: #fff;
    -webkit-transition-delay: 0.1s;
    -o-transition-delay: 0.1s;
    transition-delay: 0.1s;
    color: #473bf0;
}
.filled-btn.btn-bordered.btn-off-white:after {
    background-color: #fff;
}
.filled-btn.bg-clear-blue {
    background-color: #3377FF;
    -webkit-box-shadow: 0px 10px 30px 0px rgb(51 119 255 / 30%);
    box-shadow: 0px 10px 30px 0px rgb(51 119 255 / 30%);
}
.filled-btn.bg-clear-blue:hover {
    -webkit-box-shadow: 0px 10px 30px 0px rgb(22 28 45 / 30%);
    box-shadow: 0px 10px 30px 0px rgb(22 28 45 / 30%);
}
.play-btn {
    height: 54px;
    width: 54px;
    background-color: #FFFFFF;
    color: #f4732a;
    font-size: 14px;
    text-align: center;
    line-height: 54px;
    border-radius: 50px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
.play-btn:hover {
    color: #0040e5;
}

/* Section Title */
.section-title h2 {
    margin-bottom: 25px;
    font-size: 42px;
    line-height: 50px;
}
.section-title h2 span {
    position: relative;
    z-index: 1;
}
.section-title .section-title-quote {
    font-family: 'CircularStdMedium';
    padding-left: 30px;
    margin-bottom: 35px;
}
.section-title .section-title-quote {
    position: relative;
}
.section-title .section-title-quote:after {
    position: absolute;
    content: "";
    left: 0;
    top: 3px;
    background-color: #F4732A;
    height: 90%;
    width: 4px;
}
.section-title .section-title-description {
    margin-bottom: 30px;
    color: #606060;
}
.section-title .section-title-description p {
    font-size: 17px;
    color: #6e727d;
    line-height: 30px;
    text-align:justify;
}
.section-title .section-heading-tag {
    margin-bottom: 26px;
}
.section-title .section-heading-tag .single-heading-tag {
    display: inline-block;
}
.section-title .section-heading-tag .single-heading-tag {
    background: #D3D4F8;
    color: #473bf0;
    font-size: 18px;
    height: 32px;
    padding: 0 20px;
    border-radius: 50px;
    line-height: 32px;
    font-family: 'CircularStdMedium';
}
.section-title .section-heading-tag .single-heading-tag.bordered-tag {
    background: transparent;
}
.section-title .section-heading-tag .single-heading-tag.bg-burning-orange{
    background-color: #F4732A;
}
.section-title .section-heading-tag .single-heading-tag.bg-transparent-ocean-blue{
    background-color: #1A53E8;
}
.section-title .section-heading-tag .single-heading-tag.bg-royal-blue{
    background: #473BF0;
    color: #fff;
}
.section-title .section-heading-tag .single-heading-tag.text-white{
    color: #fff;
}
.section-title .section-heading-tag .single-heading-tag.text-old-lavender {
    color: #6f667b;
}
.section-title.section-title-ocean-blue .section-heading-tag .single-heading-tag {
    background-color: #E5ECFC;
    color: #0040E5;
}
.section-title.section-title-ocean-blue .section-heading-tag {
    margin-bottom: 27px;
}
.section-title.section-title-ocean-blue h2 span {
    color: #0040e5;
}
.section-title h2 span:after{
    position: absolute;
    content: "";
    height: 30px;
    width: 100%;
    left: 0;
    bottom: -30px;
    z-index: -1;
    background-attachment: scroll;
    background-position: bottom;
    background-repeat: no-repeat;
    padding-bottom: 8px;
    background-size: contain;
}
.section-title.section-title-white.section-title-white-v2 h2 span:after{
    bottom: -30px;
}
.section-title.section-title-tuna h2 span:after, 
.section-title.section-title-mirage h2 span:after,
.section-title.section-title-white.section-title-white-v2 h2 span:after{
    background-image: url(../img/hero/particle.png);
}

.section-title.section-title-tuna h2, 
.section-title.section-title-mirage h2{
    margin-bottom: 35px;
}
.section-title.section-title-tuna .section-heading-tag{
    margin-bottom: 15px;
}
.section-title.section-title-ocean-blue h2{
    margin-bottom: 48px;
}
.section-title.section-title-tuna .section-title-description p{
    color: #6f667b;
}
.section-title.section-title-ocean-blue .section-button-wrapper .filled-btn {
    background: #0040E5;
}
.section-title.section-title-white,
.section-title.section-title-white .section-title-description p {
    color: #fff;
}
.section-title.section-title-white .section-title-quote:after {
    background-color: #fff;
}
.section-title.section-title-tuna {
    color: #36234b;
}
.section-title.section-title-mirage {
    color: #36234b;
}
.section-title.section-title-mirage .section-heading-tag,
.section-title.section-title-white .section-heading-tag{
    margin-bottom: 15px;
}
.section-title.section-title-mirage .section-heading-tag .single-heading-tag{
    color: #6e727d;
    background: transparent;
    padding: 0;
}
.section-title.section-title-white .filled-btn.btn-bordered {
    border: 2px solid #356CFB;
    color: #fff;
}
.section-title.section-title-white .filled-btn.btn-bordered:hover:after,
.section-title.section-title-white .filled-btn.btn-bordered:after{
    background-color: #fff;
}
.section-title.section-title-white .filled-btn.btn-bordered:hover {
    border-color: #fff;
    color: #0040e5;
}
.section-title.section-title-white .filled-btn.btn-bordered:hover{
    background-color: #fff;
    -webkit-transition-delay: 0.1s;
         -o-transition-delay: 0.1s;
            transition-delay: 0.1s;
}
.section-title .section-button-wrapper.section-dual-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 11px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.section-title.section-title-clear-blue .single-heading-tag {
    padding: 0;
    background: transparent;
    color: #3377ff;
    font-family: 'CircularStdBold';
}
.section-title.section-title-clear-blue .section-heading-tag {
    margin-bottom: 15px;
}


/* List Style */
.section-list-style ul li {
    font-size: 18px;
    color: #6e727d;
    position: relative;
    z-index: 1;
    padding-left: 30px;
    margin-bottom: 10px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
.section-list-style ul li:after {
    position: absolute;
    content: "\f00c";
    left: 0;
    top: 2px;
    font-family: "Font Awesome 5 Pro";
    font-size: 16px;
    color: #473bf0;
    font-weight: 800;
}
.section-list-style ul li:last-of-type {
    margin: 0;
}
.section-list-style ul li:hover {
    color: #473bf0;
}

/* List Style V2 */
.section-list-style.list-style-v2{
    margin-bottom: 46px;
}
.section-list-style.list-style-v2 ul li:after {
    background-color: #E25C6A;
    color: #fff;
    height: 25px;
    width: 25px;
    text-align: center;
    line-height: 25px;
    border-radius: 50%;
    font-size: 13px;
}
.section-list-style.list-style-v2 ul li {
    padding-left: 40px;
    color: #161c2d;
    margin-bottom: 13px;
}
.section-list-style.list-style-v2 ul li:hover {
    color: #161c2d;
}

/* List Style V3 */
.section-list-style.list-style-v3 ul li:after {
    content: "";
    font-family: inherit;
    height: 7px;
    width: 7px;
    background-color: #3377ff;
    border-radius: 50%;
    top: 15px;
    margin-top: -3.5px;
}
.section-list-style.list-style-v3 ul li:hover {
    color: #3377ff;
}
/* Header CSS */
.header-navigation .brand-logo a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 10px;
    color: #161c2d;
}
.header-navigation .brand-logo.text-logo i{
    font-size: 35px;
}
.header-navigation .brand-logo.text-logo a span{
    font-size: 40px;
    font-family: 'CircularStdBold';
}
.header-navigation .brand-logo.text-logo a:hover {
    color: #161c2d;
}
.header-navigation .main-menu ul li {
    display: inline-block;
    position: relative;
}
.header-navigation .main-menu ul li>a {
    display: block;
    font-size: 16px;
    color: #161c2d;
    font-weight: 500;
    text-transform: capitalize;
    padding: 40px 18px;
    line-height: 1;
    font-family: 'CircularStdMedium';
}
.header-navigation .main-menu ul li>a.nav-link:hover, 
.header-navigation .main-menu ul li>a.nav-link.active{
    color: #473BF0;
}
.header-navigation .main-menu ul li .sub-menu {
    position: absolute;
    left: 0;
    top: 120%;
    width: 200px;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    z-index: 99;
    height: auto;
    text-align: left;
    -webkit-box-shadow: 0 8px 20px rgb(55 125 255 / 8%);
    box-shadow: 0 8px 20px rgb(55 125 255 / 8%);
}
.header-navigation .main-menu ul li .sub-menu li {
    display: block;
    margin: 0;
}
.header-navigation .main-menu ul li .sub-menu li:last-child>a {
    border-bottom: none;
}
.header-navigation .main-menu ul li .sub-menu li a {
    display: block;
    padding: 8px 15px;
    font-size: 16px;
    position: relative;
    -webkit-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    border-radius: 0;
    line-height: 2;
    margin: 0;
    border-bottom: 1px solid #ececec;
    color: #1b3255;
}
.header-navigation .main-menu ul li .sub-menu li a:hover {
    background-color: #473BF0;
    color: #fff;
    border-color: transparent;
}
.header-navigation .main-menu ul li .sub-menu li .sub-menu {
    left: 100%;
    top: 50%;
}
.header-navigation .main-menu ul li .sub-menu li:hover .sub-menu {
    top: 0%;
}
.header-navigation .main-menu ul li:hover.menu-item-has-children>a:after {
    color: #473BF0;
}
.header-navigation .main-menu ul li:hover>.sub-menu {
    opacity: 1;
    visibility: visible;
    top: 100%;
}
.header-navigation .main-menu ul li .dd-trigger {
    display: none;
}
.header-navigation.breakpoint-on .nav-menu {
    text-align: left;
    background-color: #fff;
    position: fixed;
    top: 0;
    right: -300px;
    z-index: 9999;
    width: 300px;
    height: 100%;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    padding: 0;
    -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    display: block;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-top: 70px;
}
.header-navigation.breakpoint-on .nav-menu.menu-on {
    right: 0;
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li:first-of-type {
    border-top: 1px solid #ececec;
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li {
    display: block;
    margin: 0;
    border-bottom: 1px solid #ececec;
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li a {
    display: block;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    color: #161c2d;
    padding: 13px 20px;
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu li a:hover,
.header-navigation.breakpoint-on .main-menu > ul > li > a:hover {
    color: #473BF0;
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
    visibility: visible;
    opacity: 1;
    display: none;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu li a {
    color: #000000;
    padding: 0px 20px 0 30px;
    line-height: 45px !important;
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu li .sub-menu li a {
    padding-left: 45px;
}
.header-navigation.breakpoint-on .main-menu ul li a.nav-link.active,
.header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu li a.nav-link.active {
    color: #473BF0;
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu li a:hover {
    border-color: rgba(255, 255, 255, 0.5);
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu li a:hover{
    background-color: transparent;
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li .dd-trigger {
    display: block;
    position: absolute;
    right: 0;
    height: 45px;
    width: 45px;
    top: 0;
    border-left: 1px solid #dcdde0;
    z-index: 2;
    background: transparent;
    text-align: center;
    line-height: 45px;
    cursor: pointer;
    color: #000000;
    font-size: 20px;
}
.header-navigation.breakpoint-on .nav-menu .main-menu .sub-menu li:last-of-type {
    border: 0;
}
.header-navigation.breakpoint-on .nav-menu .main-menu.menu-on {
    left: 0;
}
.header-navigation.breakpoint-on .nav-pushed-item {
    display: none;
}
.header-navigation.breakpoint-on .navbar-close, .header-navigation.breakpoint-on .navbar-toggler {
    display: block;
}
.header-navigation .navbar-toggler {
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    display: none;
}
.header-navigation .navbar-toggler span {
    position: relative;
    background-color: #000000;
    border-radius: 3px;
    display: block;
    height: 3px;
    margin-top: 5px;
    padding: 0;
    -webkit-transition-duration: 300ms;
    -o-transition-duration: 300ms;
    transition-duration: 300ms;
    width: 30px;
    cursor: pointer;
    display: block;
}
.header-navigation .navbar-toggler span:first-of-type {
    margin-top: -2px;
}
.header-navigation .navbar-toggler.active span:nth-of-type(1) {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 9px;
}
.header-navigation .navbar-toggler.active span:nth-of-type(2) {
    opacity: 0;
}
.header-navigation .navbar-toggler.active span:nth-of-type(3) {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: -7px;
}
.header-navigation .navbar-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 12;
    display: none;
    background: #473BF0;
    padding: 5px;
}
.header-navigation .navbar-close .cross-wrap {
    width: 34px;
    height: 34px;
    cursor: pointer;
    position: relative;
}
.header-navigation .navbar-close .cross-wrap span {
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 6px;
    background: #fff;
}
.header-navigation .navbar-close .cross-wrap span.top {
    top: 17px;
    left: 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.header-navigation .navbar-close .cross-wrap span.bottom {
    bottom: 15px;
    left: 0;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.header-navigation .header-right ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}
.header-navigation .header-right li {
    display: inline-block;
}
.header-navigation .header-right li a.search-btn {
    padding-right: 30px;
    border-right: 2px solid #dcdde0;
}
.header-navigation .header-right li a.search-btn i {
    font-weight: 600;
}
.header-navigation .header-right li.get-started-wrapper {
    margin-left: 30px;
}
.header-navigation .site-branding-and-language-selection {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

/* Menu Active Overlay */
.header-navigation .menu-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #111;
    cursor: pointer;
    z-index: 999;
    -webkit-transition: all 0.5s ease-out 0s;
    -o-transition: all 0.5s ease-out 0s;
    transition: all 0.5s ease-out 0s;
    opacity: 0;
    visibility: hidden;
}
.header-navigation.breakpoint-on .menu-toggle.active .menu-overlay {
    opacity: 0.5;
    visibility: visible;
}

/* Header OffCanvas Menu */
.header-navigation .nav-tools .icon {
    margin-left: 5px;
    width: 50px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    border-radius: 50%;
}
.header-navigation .nav-tools .icon.off-menu span {
    width: 30px;
    height: 3px;
    background-color: #473BF0;
    display: block;
    margin: 3px 0;
    -webkit-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    margin-left: auto;
}

/* Sticky Menu */
.header-area.header-v1 .header-navigation {
    position: relative;
    z-index: 2;
}
.header-area .header-navigation.sticky {
    background-color: #1C1E22;
}
.header-area .header-navigation.sticky {
    background-color: #fff;
    -webkit-box-shadow: 0 8px 20px rgb(55 125 255 / 8%);
    box-shadow: 0 8px 20px rgb(55 125 255 / 8%);
}
.header-area .header-navigation.sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    -webkit-animation: sticky 1.2s;
    animation: sticky 1.2s;
}
@-webkit-keyframes sticky {
    0% {
        top: -200px;
    }
    100% {
        top: 0;
    }
}
@keyframes sticky {
    0% {
        top: -200px;
    }
    100% {
        top: 0;
    }
}


/* Single Service Box */
.single-service-box {
    background: #fff;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(225, 223, 255, 0.4);
    box-shadow: 0px 10px 30px 0px rgba(225, 223, 255, 0.4);
    padding: 50px 50px;
    text-align: center;
    position: relative;
    margin-bottom: 30px;
}
.single-service-box .service-thumbnail {
    margin-bottom: 28px;
}
.single-service-box .service-box-title {
    margin-bottom: 24px;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
}
.single-service-box:after {
    position: absolute;
    content: "";
    height: 3px;
    width: 0;
    top: 0;
    left: 0;
    background: #473bf0;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
.single-service-box:hover:after {
    width: 100%;
}
.single-service-box.active:after {
    width: 100%;
}
.single-service-box .service-box-btn a{
    height: 55px;
    width: 55px;
    background: #fff;
    border-radius: 50px;
    line-height: 50px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    position: relative;
    z-index: 1;
    display: inline-block;
    color: #473bf0;
    border: 2px solid #ECEBFD;
}
.single-service-box .service-box-btn a:hover {
    border-color: #473bf0;
}
.single-service-box:hover .service-box-btn a {
    border-color: #473bf0;
}

/* Single Service Box V2 */
.single-service-box.single-service-box-v2 {
    border-radius: 7px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    z-index: 1;
    height: 100%;
    padding: 43px 45px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-bottom: 30px;
}
.single-service-box.single-service-box-v2:hover,
.single-service-box.active.single-service-box-v2  {
    -webkit-box-shadow: 0px 10px 60px 0px rgba(184, 179, 252, 0.6);
            box-shadow: 0px 10px 60px 0px rgba(184, 179, 252, 0.6);
    -webkit-transform: translate(0px, -10px);
        -ms-transform: translate(0px, -10px);
            transform: translate(0px, -10px);
}
.single-service-box.single-service-box-v2:after {
    height: 5px;
    border-radius: 7px 7px 0 0;
}
.single-service-box.single-service-box-v2 .service-box-title {
    font-size: 22px;
}
.single-service-box.single-service-box-v2 .service-thumbnail {
    background-color: #F2F5FE;
    height: 180px;
    width: 180px;
    margin: 0 auto;
    line-height: 180px;
    border-radius: 50%;
    margin-bottom: 30px;
}
.single-service-box.single-service-box-v2 .service-thumbnail {
    margin: 0 auto;
    margin-bottom: 25px;
}

/* Single Service Box V3 (Home V6) */
.single-service-box.single-service-box-v3 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-align: left;
    border-radius: 15px;
    padding: 40px 50px;
    z-index: 2;
    position: relative;
}
.single-service-box.single-service-box-v3:hover {
    -webkit-box-shadow: 0px 10px 45px 0px rgba(71, 59, 240, 0.1);
            box-shadow: 0px 10px 45px 0px rgba(71, 59, 240, 0.1);
}
.single-service-box.single-service-box-v3:before {
    content: "";
    height: 100%;
    width: 100%;
    background-color: #fff;
    left: 0;
    top: 0;
    z-index: -1;
    position: absolute;
    border-radius: 10px;
    -webkit-transition: all .3s linear;
    -o-transition: all .3s linear;
    transition: all .3s linear;
    -webkit-box-shadow: 0px 10px 60px 0px rgb(189 189 189 / 30%);
    box-shadow: 0px 10px 60px 0px rgb(189 189 189 / 30%);
    visibility: hidden;
    opacity: 0;
    -webkit-transform: rotateY(-70deg);
    -ms-transform: rotateY(-70deg);
    transform: rotateY(-70deg);
    -webkit-transform-origin: left;
    -ms-transform-origin: left;
    transform-origin: left;
}
.single-service-box.single-service-box-v3:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    z-index: -2;
    background-color: #3275FF;
    top: 0;
    left: 0;
    border-radius: 15px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .3s linear;
    -o-transition: all .3s linear;
    transition: all .3s linear;
    opacity: 0;
    -webkit-transform: rotateY(-70deg);
    -ms-transform: rotateY(-70deg);
    transform: rotateY(-70deg);
    -webkit-transform-origin: left;
    -ms-transform-origin: left;
    transform-origin: left;
}
.single-service-box.single-service-box-v3:hover:before {
    opacity: 1;
    right: -5px;
    left: auto;
    top: 5px;
    visibility: visible;
    -webkit-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
}
.single-service-box.single-service-box-v3:hover:after {
    opacity: 1;
    top: -5px;
    left: -5px;
    visibility: visible;
    -webkit-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
}
.single-service-box.single-service-box-v3 .service-thumbnail {
    min-width: 130px;
    max-width: 130px;
    margin-bottom: 0;
    margin-right: 34px;
}
.single-service-box.single-service-box-v3 .service-thumbnail img {
    border-radius: 50%;
}
.single-service-box.single-service-box-v3  .service-box-title {
    margin-bottom: 3px;
}
.single-service-box.single-service-box-v3 .service-box-content p {
    color: #7a7a7a;
    font-size: 17px;
    line-height: 30px;
    margin-bottom: 11px;
}
.single-service-box.single-service-box-v3 .service-box-content .service-box-btn a {
    width: auto;
    border: 0;
    border-radius: 0;
    height: auto;
    line-height: 1;
    color: #7a7a7a;
    font-size: 15px;
    font-family: 'CircularStdBold';
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    background-color: transparent;
}
.single-service-box.single-service-box-v3 .service-box-content .service-box-btn a:hover {
    color: #3275FF;
}
.single-service-box.single-service-box-v3 .service-box-content .service-box-btn a i {
    margin-left: 9px;
}
/* 03. Data Analysis Feature Area */
.data-analysis-feature-area .data-analysis-content .section-title .section-title-description {
    margin-bottom: 23px;
}
.data-analysis-feature-area .data-analysis-preview-wrapper {
    position: relative;
    height: 645px;
}
.data-analysis-feature-area .data-analysis-preview-wrapper .data-analysis-preview{
    border-radius: 10px;
    -webkit-box-shadow: 0 10px 30px 0px rgb(185 182 235 / 40%);
            box-shadow: 0 10px 30px 0px rgb(185 182 235 / 40%);
}
.data-analysis-feature-area .data-analysis-preview-1 {
    position: relative;
    z-index: 1;
}
.data-analysis-feature-area .data-analysis-preview-2 {
    position: absolute;
    left: 26%;
    z-index: 2;
    top: 75px;
}
.data-analysis-feature-area .data-analysis-preview-3 {
    position: absolute;
    right: 80px;
    z-index: 3;
    top: 25px;
}
.data-analysis-feature-area .data-analysis-preview-4 {
    position: absolute;
    left: 30px;
    z-index: 1;
    bottom: 0;
}
.data-analysis-feature-area .data-analysis-preview-5 {
    position: absolute;
    right: 20%;
    z-index: 4;
    bottom: 78px;
}
.data-analysis-feature-area .data-analysis-preview-6 {
    position: absolute;
    right: 65px;
    bottom: 40%;
    z-index: 1;
}

/* 04. What We Do / Services Area */
.our-services.our-services-v1 {
    background-size: contain;
    background-position: top center;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-color: #F2F5FE;
}
.our-services .service-area-internal {
    position: relative;
    z-index: 1;
}
.our-services .service-area-internal .section-particle-effect .particle-1 {
    top: 30%;
}
.our-services .service-area-internal .section-particle-effect .particle-3 {
    top: -10%;
}

/* Single Feature Box */
.single-feature-box {
    border: 2px solid #262C3C;
    padding: 42px 44px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 30px;
}
.single-feature-box .feature-box-content h4 {
    color: #fff;
    margin-bottom: 11px;
    font-size: 24px;
    text-transform: capitalize;
}
.single-feature-box .feature-box-icon {
    max-width: 70px;
    margin-right: 40px;
    min-width: 70px;
    line-height: 0;
}
.single-feature-box .feature-box-icon img,
.single-feature-box .feature-box-icon i {
    -webkit-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}
.single-feature-box:hover .feature-box-icon img,
.single-feature-box:hover .feature-box-icon i {
    -webkit-transform: rotateY(360deg);
            transform: rotateY(360deg);
}
.single-feature-box .feature-box-icon i {
    font-size: 75px;
    color: #473BF0;
    -webkit-transition-delay: 0s;
         -o-transition-delay: 0s;
            transition-delay: 0s;
}
.single-feature-box .feature-box-content p {
    color: #a8acb7;
    font-size: 17px;
    line-height: 30px;
}
.single-feature-box {
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
.single-feature-box:hover {
    border: 2px solid #473BF0;
}

/* Single Feature Box V2 */
.single-feature-box.single-feature-box-v2 {
    -webkit-box-shadow: 0px 10px 30px 0px rgba(219, 219, 219, 0.41);
            box-shadow: 0px 10px 30px 0px rgba(219, 219, 219, 0.41);
    border: 0;
    border-radius: 7px;
    position: relative;
}
.single-feature-box.single-feature-box-v2 .feature-box-content h4 {
    color: #161c2d;
}
.single-feature-box.single-feature-box-v2 .feature-box-content p {
    color: #6e727d;
}
.single-feature-box.single-feature-box-v2 .feature-box-icon i {
    color: #FF0101;
}
.single-feature-box.single-feature-box-v2.single-feature-box-v2-color-1 .feature-box-icon i {
    color: #FF0101;
}
.single-feature-box.single-feature-box-v2.single-feature-box-v2-color-2 .feature-box-icon i {
    color: #dc64ff;
}
.single-feature-box.single-feature-box-v2.single-feature-box-v2-color-3 .feature-box-icon i {
    color: #2f84fb;
}
.single-feature-box.single-feature-box-v2.single-feature-box-v2-color-4 .feature-box-icon i {
    color: #ff6c02;
}
.single-feature-box.single-feature-box-v2.single-feature-box-v2-color-5 .feature-box-icon i {
    color: #56fb80;
}
.single-feature-box.single-feature-box-v2.single-feature-box-v2-color-6 .feature-box-icon i {
    color: #6173fa;
}

/*  Single Feature Box V2 Border Effect */
.single-feature-box.single-feature-box-v2:before, .single-feature-box.single-feature-box-v2:after {
    position: absolute;
    content: "";
    height: 5px;
    width: 5px;
    top: 0;
    left: 0;
    background-color: #FF0101;
    border-radius: 50px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    opacity: 0;
}
.single-feature-box.single-feature-box-v2:after {
    top: auto;
    bottom: 0;
    -webkit-transition-delay: 0.2s;
         -o-transition-delay: 0.2s;
            transition-delay: 0.2s;
}
.single-feature-box.single-feature-box-v2:before {
    -webkit-transition-delay: 0.1s;
         -o-transition-delay: 0.1s;
            transition-delay: 0.1s;
}
.single-feature-box.single-feature-box-v2:hover:before {
    border-radius: 2.5px 2.5px 0 0;
}
.single-feature-box.single-feature-box-v2:hover:after {
    border-radius: 0 0 2.5px 2.5px;
}
.single-feature-box.single-feature-box-v2:hover:before, .single-feature-box.single-feature-box-v2:hover:after {
    opacity: 1;
    height: 50%;
}

.single-feature-box.single-feature-box-v2.single-feature-box-v2-color-1:before, .single-feature-box.single-feature-box-v2.single-feature-box-v2-color-1:after {
    background-color: #FF0101;
}
.single-feature-box.single-feature-box-v2.single-feature-box-v2-color-2:before, .single-feature-box.single-feature-box-v2.single-feature-box-v2-color-2:after {
    background-color: #dc64ff;
}
.single-feature-box.single-feature-box-v2.single-feature-box-v2-color-3:before, .single-feature-box.single-feature-box-v2.single-feature-box-v2-color-3:after {
    background-color: #2f84fb;
}
.single-feature-box.single-feature-box-v2.single-feature-box-v2-color-4:before, .single-feature-box.single-feature-box-v2.single-feature-box-v2-color-4:after {
    background-color: #ff6c02;
}
.single-feature-box.single-feature-box-v2.single-feature-box-v2-color-5:before, .single-feature-box.single-feature-box-v2.single-feature-box-v2-color-5:after {
    background-color: #56fb80;
}
.single-feature-box.single-feature-box-v2.single-feature-box-v2-color-6:before, .single-feature-box.single-feature-box-v2.single-feature-box-v2-color-6:after {
    background-color: #6173fa;
}

/* Single Feature Box V3 */
.single-feature-box.single-feature-box-v3 {
    border-radius: 7px;
    border: 2px solid rgb(71 59 240 / 7%);
    position: relative;
    z-index: 1;
    overflow: hidden;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
.single-feature-box.single-feature-box-v3:hover {
    border-color: #473BF0;
    background-color: #473BF0;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(71, 59, 240, 0.36);
            box-shadow: 0px 10px 30px 0px rgba(71, 59, 240, 0.36);
}
.single-feature-box.single-feature-box-v3:hover .feature-box-content h4, .single-feature-box.single-feature-box-v3:hover .feature-box-content p {
    color: #fff;
}
.single-feature-box.single-feature-box-v3 .feature-box-content h4 {
    color: #161c2d;
}
.single-feature-box.single-feature-box-v3 .feature-box-content p {
    color: #6e727d;
}
.single-feature-box.single-feature-box-v3:after{
    position: absolute;
    content: "";
    background: #473BF0;
    height: 100px;
    width: 100px;
    border-radius: 50px;
    left: 50%;
    top: 34%;
    z-index: -1;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
    margin-left: -50px;
    margin-top: -30px;
    /* background-image: url(../img/services/single-service-bg.png); */
    background-size: contain;
    background-position: left bottom;
    background-repeat: no-repeat;
    opacity: 0;
}
.single-feature-box.single-feature-box-v3:hover:after{
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    height: 100%;
    width: 100%;
    border-radius: 5px;
    margin: 0;
    left: 0;
    top: 0;
    opacity: 1;
}
.single-feature-box.single-feature-box-v3:hover .feature-box-icon i {
    color: #fff;
}

/* 05. App Statistic Area */
.app-statistic-area .statistic-preveiw-thumbnail {
    position: relative;
    z-index: 2;
}
.app-statistic-area .statistic-preveiw-thumbnail:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 80%;
    right: 0;
    background: #F2F1FE;
    top: 0;
    z-index: -2;
    opacity: 0.7;
}
.app-statistic-area .statistic-preveiw-thumbnail:before {
    position: absolute;
    content: '';
    height: 100%;
    width: 70%;
    z-index: -1;
    right: 0;
    top: 0;
    /* background-image: url(../img/app/statistic-pattern.png); */
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.app-statistic-area .statistic-preveiw-thumbnail .statistic-thumbnail-4 {
    margin-top: 25px;
    margin-left: -30px;
}
.app-statistic-area .statistic-preveiw-thumbnail .statistic-thumbnail-3 {
    margin-top: -25px;
}
.app-statistic-area .statistic-preveiw-thumbnail {
    padding-top: 80px;
    padding-bottom: 50px;
}
.app-statistic-area .statistic-preveiw-thumbnail .statistic-thumbnail-2 {
    margin-left: -30px;
}
.app-statistic-area .simple-icon-box-wrapper{
    gap: 40px;
}

/* Company Statistic Area (Home V6) */
.our-statistics-images {
    position: relative;
}
.our-statistics-images .our-statistics-image-main img {
    width: 100%;
    -webkit-box-shadow: 0px 10px 60px 0px rgb(51 119 255 / 15%);
    box-shadow: 0px 10px 60px 0px rgb(51 119 255 / 15%);
    border-radius: 10px;
}
.our-statistics-images .our-statistics-image-main {
    margin-top: 63px;
    margin-bottom: 63px;
    margin-left: 30px;
    margin-right: 30px;
}
.our-statistics-images .our-statistics-image-relative, 
.our-statistics-images .our-statistics-image-absolute {
    -webkit-box-shadow: 0px 10px 60px 0px rgb(51 119 255 / 15%);
    box-shadow: 0px 10px 60px 0px rgb(51 119 255 / 15%);
}
.our-statistics-images .our-statistics-image-relative, 
.our-statistics-images .our-statistics-image-absolute {
    position: absolute;
    z-index: 1;
}
.our-statistics-images .our-statistics-image-relative {
    right: 0;
    top: -63px;
}
.our-statistics-images .our-statistics-image-absolute {
    bottom: -62px;
    left: 0;
}

/* 06. Testimonial Area */
.testimonial-area {
    border-top: 1px solid rgb(71 59 240 / 10%);
}
.testimonial-area .section-internal {
    position: relative;
    z-index: 1;
}
.testimonial-area .section-particle-effect.section-particle-effect-v2 .particle-5 {
    right: -250px;
    bottom: 0;
}
.testimonial-area .section-particle-effect.section-particle-effect-v2 .particle-1 {
    top: 90px;
}
.testimonial-area .section-particle-effect.section-particle-effect-v2 .particle-3 {
    top: 90px;
    right: -220px;
}

/* Single Testimonial */
.single-testimonial {
    text-align: center;
}
.single-testimonial .testimonial-author {
    text-align: center;
}
.single-testimonial .testimonial-author img {
    margin: 0 auto;
    border-radius: 50%;
}
.single-testimonial .testimonial-author {
    margin-top: 30px;
}
.single-testimonial .testimonial-author h6 span {
    display: block;
    color: #6e727d;
    font-size: 15px;
    font-family: 'CircularStdMedium';
    font-weight: 400;
    text-transform: capitalize;
}
.single-testimonial .testimonial-author h6 {
    font-size: 18px;
    color: #161c2d;
    margin-top: 19px;
    line-height: 25px;
}
.single-testimonial .quote-thumbnail{
    text-align: center;
    margin-bottom: 2px;
}
.single-testimonial .quote-thumbnail img {
    margin: 0 auto;
    margin-bottom: 30px;
}
.single-testimonial .quote-thumbnail i {
    color: #473BF0;
    font-size: 88px;
}
.single-testimonial p {
    font-size: 20px;
    color: #161c2d;
    line-height: 35px;
    font-family: 'CircularStdMedium';
}
.testimonial-slider .slick-slide {
    margin: 0 0px;
}
.testimonial-slider .slick-list {
    margin: 0 -15px;
}


/* Testimonial Custom Rating */
.single-testimonial .rating-review li {
    display: inline-block;
}
.single-testimonial .rating-review li {
    color: #FFA800;
}
.single-testimonial .rating-review li:last-child a {
    color: #96a6a7;
    padding-left: 5px;
}
.single-testimonial .rating-review .all-rating {
    height: 25px;
    overflow: hidden;
    position: relative;
    width: 100%;
}
.single-testimonial .rating-review .all-rating .blank-rating {
    bottom: 0;
    color: #f19e1f;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}
.single-testimonial .rating-review .all-rating.star-5 .main-rating {
    width: 100%;
}
.single-testimonial .rating-review .all-rating.star-5 .blank-rating {
    left: 100%;
}
.single-testimonial .rating-review .all-rating.star-4 .main-rating {
    width: 81%;
}
.single-testimonial .rating-review .all-rating.star-4 .blank-rating {
    left: 81%;
}
.single-testimonial .rating-review .all-rating.star-3 .main-rating {
    width: 60%;
}
.single-testimonial .rating-review .all-rating.star-3 .blank-rating {
    left: 60%;
}
.single-testimonial .rating-review .all-rating.star-2 .main-rating {
    width: 38%;
}
.single-testimonial .rating-review .all-rating.star-2 .blank-rating {
    left: 41%;
}
.single-testimonial .rating-review .all-rating.star-1 .main-rating {
    width: 12%;
}
.single-testimonial .rating-review .all-rating.star-1 .blank-rating {
    left: 20%;
}

/* Testimonial Sliders Arrows */
.testimonial-slider .slick-arrow {
    height: 50px;
    width: 50px;
    background-color: #473bf0;
    text-align: center;
    line-height: 50px;
    font-size: 15px;
    color: #fff;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -25px;
    z-index: 1;
    cursor: pointer;
    border: 2px solid #473bf0;
}
.testimonial-slider .next.slick-arrow {
    left: auto;
    right: 0;
}
.testimonial-slider .slick-arrow.slick-disabled {
    background-color: #f4f7fa;
    border-color: rgb(22 28 45 / 10%);
    color: #473bf0;
}

/* Testimonial Sliders Dots */
.testimonial-slider .slick-dots {
    text-align: center;
    margin-top: 0px;
}
.testimonial-slider .slick-dots li {
    display: inline-block;
    height: auto;
}
.testimonial-slider .slick-dots li {
    height: 12px;
    width: 12px;
    background: #DAD8FC;
    border-radius: 50px;
    margin: 0 10px;
}
.testimonial-slider .slick-dots li {
    position: relative;
}
.testimonial-slider .slick-dots li:after {
    position: absolute;
    content: "";
    height: 30px;
    width: 30px;
    background: transparent;
    z-index: -1;
    border-radius: 50px;
    left: -9px;
    top: -9px;
    border: 2px solid #473bf0;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    z-index: 1;
}
.testimonial-slider .slick-dots li {
    margin: 0 15px;
}
.testimonial-slider .slick-dots li.slick-active:after {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}
.testimonial-slider .slick-dots li.slick-active {
    background: #473bf0;
}



/* Single Counter Up Box */
.single-counter-up .counterup-icon i {
    font-size: 30px;
    width: 80px;
    height: 80px;
    background: rgb(255 255 255 / 7%);
    text-align: center;
    line-height: 80px;
    color: #fff;
    border-radius: 50px;
}
.single-counter-up .counterup-icon {
    margin-bottom: 24px;
}
.single-counter-up .counterup-info h2 {
    font-size: 42px;
    color: #fff;
    font-family: 'CircularStdBook';
    font-weight: 400;
}
.single-counter-up .counterup-info p {
    font-size: 17px;
    color: #fff;
}
.single-counter-up {
    margin-bottom: 80px;
}
.single-counter-up .counterup-icon i {
    position: relative;
    z-index: 1;
}
.single-counter-up .counterup-icon i:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background-color: #fff;
    left: 0;
    z-index: -1;
    border-radius: 50%;
    top: 0;
    transform: scale(0.2);
    transition: 0.3s;
    opacity: 0;
}
.single-counter-up:hover .counterup-icon i:after {
    transform: scale(1);
    opacity: 1;
}
.single-counter-up:hover .counterup-icon i {
    color: #473bf0;
}

/* Counter Up Box V2 */
.single-counter-up.single-counter-up-v2 .counterup-icon i:after {
    display: none;
}.single-counter-up.single-counter-up-v2:hover .counterup-icon i {
    color: #fff;
}
.single-counter-up.single-counter-up-v2 {
    margin-bottom: 30px;
}
.counter-up-area .counterup-internal {
    padding: 63px 75px 33px 75px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 0px 20px rgb(244 115 42 / 7%);
            box-shadow: 0px 0px 0px 20px rgb(244 115 42 / 7%);
    background-position: bottom center;
    background-size: contain;
    background-attachment: scroll;
    background-repeat: no-repeat;
    position: relative;
}
.counterup-internal.bg-royal-blue{
    -webkit-box-shadow: 0px 0px 0px 20px rgb(71 59 240 / 7%);
            box-shadow: 0px 0px 0px 20px rgb(71 59 240 / 7%);
}
.single-counter-up.single-counter-up-v2 .counterup-info h2 {
    min-width: 80px;
		/*
    max-width: 80px;
    margin-right: 30px;
    */
		position: relative;
    margin-bottom: 0;
    line-height: 1;
}
.single-counter-up.single-counter-up-v2 .counterup-info h2 .sign {
    font-size: 24px;
    top: -10px;
    position: absolute;
    /* right: -80px; */
}
.single-counter-up.single-counter-up-v2 .counterup-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    flex-direction: column;
}
.single-counter-up.single-counter-up-v2 p {
    font-size: 18px;
    line-height: 25px;
    text-align: left;
}
.single-counter-up.single-counter-up-v2 .counterup-info h2 .counter {
    font-size: 85px;
}

/* Counter Up Box (Home V5) */
.statistic-count-boxes .single-counter-up.single-counter-up-v2 {
    margin-bottom: 55px;
}
.statistic-count-boxes .single-counter-up.single-counter-up-v2 .counterup-info .counter {
    color: #161c2d;
    font-size: 80px;
}
.statistic-count-boxes .single-counter-up.single-counter-up-v2 .counterup-info  .sign {
    color: #473bf0;
}
.statistic-count-boxes .single-counter-up.single-counter-up-v2 .counterup-info p {
    color: #7a7a7a;
}

/* Fact and CTA Area (Home V6) */
.fact-and-cta-area .single-counter-up.single-counter-up-v2 .counterup-info h2 .sign {
    color: #ffa800;
}

/* Simple Counter Up */
.simple-counter-up .single-counter-up.single-counter-up-v2 .counterup-info h2 .counter {
    color: #36234b;
}
.simple-counter-up .single-counter-up.single-counter-up-v2 .counterup-info h2 .sign {
    color: #f4732a;
}
.simple-counter-up .single-counter-up.single-counter-up-v2 .counterup-info {
    display: block;
}
.simple-counter-up .single-counter-up.single-counter-up-v2 p {
    color: #36234b;
    font-family: 'CircularStdMedium';
}

/* 08. Call to Action Area */
.cta-area {
    padding-top: 100px;
    padding-bottom: 100px;
}
.cta-area .section-title .section-button-wrapper.section-dual-button{
    gap: 10px;
}
.cta-area .cta-section-particle .cta-particle-left {
    position: absolute;
    left: 0;
    top: 30px;
    max-width: 180px;
}
.cta-area .cta-section-particle .cta-particle-right {
    position: absolute;
    right: 0;
    top: 30px;
    max-width: 160px;
}
.cta-area .cta-content-internal {
    position: relative;
}
.cta-area .cta-content .section-title .section-heading-tag {
    margin-bottom: 26px;
}
.cta-area .cta-content .section-title h2 {
    margin-bottom: 38px;
    line-height: 50px;
}
.cta-area.bg-ocean-blue .cta-content .section-title h2{
    margin-bottom: 28px;
}

/* Call to Action V2 */
.cta-area.cta-area-v2 {
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    padding-top: 120px;
    padding-bottom: 130px;
}
.cta-area.cta-area-v2:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    background-color: #0B1224;
    opacity: 0.75;
}
.cta-area.cta-area-v2 .cta-content .filled-btn {
    box-shadow: none;
}
.cta-area.cta-area-v2 .cta-content .filled-btn:hover {
    background-color: #fff;
    color: #161C2D;
    -webkit-transition-delay: 0.1s;
    -o-transition-delay: 0.1s;
    transition-delay: 0.1s;
}
.cta-area.cta-area-v2 .cta-content .filled-btn:after {
    background-color: #fff;
}
.cta-area.cta-area-v2 .cta-content .filled-btn.btn-bordered {
    border-color: #8991A7;
}
.cta-area.cta-area-v2 .cta-content .filled-btn.btn-bordered:hover {
    border-color: #fff;
    color: #161c2d;
}
.cta-area.cta-area-v2 .cta-content  .section-title .section-heading-tag {
    margin-bottom: 15px;
}
.cta-area.cta-area-v2 .cta-content  .section-title .section-heading-tag .single-heading-tag {
    padding: 0;
    height: auto;
    color: #fff;
    background-color: transparent;
}
/* Simple CTA Area (Home V5) */
.simple-cta-area .simple-cta-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    padding: 30px 0 32px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
}
.simple-cta-area .simple-cta-content h4 {
    color: #fff;
    font-size: 24px;
    font-family: 'CircularStdBook';
    font-weight: 400;
}

/* App CTA Area */
.app-cta-area .section-title h2 {
    margin-bottom: 56px;
}
.app-cta-area .section-title .section-title-description{
    margin-bottom: 33px;
}

/* Video and CTA Area (Home V6) */

/* fact-and-cta */
.fact-and-cta-area {
    position: relative;
    z-index: 2;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    background-attachment: fixed;
}
.fact-and-cta-area:after {
    position: absolute;
    content: "";
    height: 45px;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-color: #fff;
}


/* Hero Area V5 (Home V5) */
.hero-area.hero-v5 {
    padding: 98px 0 30px;
    background-size: contain;
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
    overflow: hidden;
}
.hero-area.hero-v5 .hero-internal,
.hero-area.hero-v5 .hero-internal .hero-content {
    position: relative;
    z-index: 1;
}
.hero-area.hero-v5 .hero-right-thumbnail {
    height: 485px;
    width: 540px;
    position: absolute;
    right: -100px;
    top: -30px;
    border-radius: 50% 50% 50% 50% / 0% 50% 50% 50%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
.hero-area.hero-v5 .hero-right-thumbnail  .hero-right-bg-shape {
    background-color: #473BF0;
    height: 100%;
    z-index: -1;
    width: 100%;
    position: absolute;
    border-radius: 50% 50% 50% 50% / 0% 50% 50% 50%;
    top: 10px;
    right: -10px;
}
.hero-area.hero-v5  .section-title h1 {
    margin-bottom: 35px;
    font-size: 75px;
    line-height: 80px;
}
.hero-area.hero-v5 .section-title .filled-btn {
    box-shadow: none;
}
.hero-area.hero-v5 .section-title .section-title-quote:after {
    background-color: #473bf0;
}
.hero-area.hero-v5 .section-title .section-title-quote {
    margin-bottom: 50px;
}
.hero-area.hero-v5 .play-btn {
    color: #473bf0;
}
.hero-area.hero-v5 .section-title .filled-btn:hover {
    background-color: #fff;
    color: #1B202F;
    -webkit-transition-delay: 0.1s;
    -o-transition-delay: 0.1s;
    transition-delay: 0.1s;
}
.hero-area.hero-v5 .section-title .filled-btn:after {
    background-color: #fff;
}
.hero-area.hero-v5 .hero-right-thumbnail:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background-color: #1b202f;
    opacity: 0.5;
    border-radius: 50% 50% 50% 50% / 0% 50% 50% 50%;
}

/* 13. FAQ Area */
.faq-area{
    overflow: hidden;
}
.faq-area .faq-content .section-title.section-title-ocean-blue h2 {
    margin-bottom: 56px;
}
.faq-area .section-internal{
    position: relative;
    z-index: 1;
}
.faq-area.bg-white-lilac{
    background-color: #F4F7FA;
}
.section-right-image.faq-right-image img {
    border-radius: 7px;
}
.section-right-image.faq-right-image{
    position: relative;
    z-index: 1;
    margin: 60px 60px 60px 0;
}
.section-right-image.faq-right-image:after{
    position: absolute;
    content: "";
    height: 120%;
    width: 120%;
    left: -20px;
    top: -45px;
    z-index: -1;
    background-image: url(../img/faq/faq-blob.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}
/* FAQ Accordion */
.section-accordion .accordion-item{
    margin-bottom: 15px;
}
.section-accordion .accordion-item:last-child {
    margin-bottom: 0;
}
.section-accordion .accordion-item .accordion-header .accordion-button {
    padding: 15px 30px;
    width: 100%;
    text-align: left;
}
.section-accordion .accordion-item .accordion-header .accordion-button, 
.section-accordion .accordion-item .accordion-body {
    background-color: #fff;
    font-family: 'CircularStdBold';
    font-size: 18px;
    /* border-radius: 30px; */
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    line-height: 28px;
}
.section-accordion .accordion-item .accordion-body {
    padding: 10px 30px 25px 30px;
    font-size: 17px;
    color: #606060;
    font-family: 'CircularStdBook';
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
.section-accordion .accordion-item .accordion-header .accordion-button[aria-expanded="true"] {
    margin-bottom: 0;
    /* border-radius: 30px 30px 0 0; */
    padding: 15px 30px 7px 30px;
}
.section-accordion .accordion-item .accordion-body {
    border-radius: 0 0 30px 30px;
}

.section-accordion .accordion-item .accordion-header .accordion-button:after {
    font-family: 'Font Awesome 5 Pro';
    /* background-image: none; */
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    content: "\f105";
    -ms-flex-negative: 0;
        flex-shrink: 0;
    margin-left: auto;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    font-size: 16px;
    margin-top: 2px;
    color: #1a1b1e;
    font-weight: 600;
}
.section-accordion .accordion-item .accordion-header .accordion-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    /* -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,border-radius .15s ease,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,border-radius .15s ease,-webkit-box-shadow .15s ease-in-out;
    -o-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease,-webkit-box-shadow .15s ease-in-out; */
}
.section-accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

/* FAQ Accordion V2 */
.section-accordion.section-accordion-v2 .accordion-item .accordion-header .accordion-button {
    padding: 13px 30px;
}
.section-accordion.section-accordion-v2 .accordion-item .accordion-header .accordion-button[aria-expanded="true"]{
    padding: 13px 30px 5px 30px;
}
.section-accordion.section-accordion-v2 .accordion-item .accordion-header .accordion-button {
    border: 2px solid #EDF2FD;
    border-radius: 7px;
}
.section-accordion.section-accordion-v2 .accordion-item .accordion-body {
    border-radius: 0 0 7px 7px;
}
.section-accordion.section-accordion-v2  .accordion-item .accordion-header .accordion-button[aria-expanded="true"] {
    border-radius: 7px 7px 0 0;
    border-color: #fff;
}
.section-accordion.section-accordion-v2 .accordion-item {
    -webkit-box-shadow: 0px 10px 60px 0px rgb(184 179 252 / 30%);
            box-shadow: 0px 10px 60px 0px rgb(184 179 252 / 30%);
    border-radius: 7px;
}

/* 14. Pricing Table */
.pricing-table {
    padding: 29px 37px 47px 37px;
    border: 2px solid #E5ECFC;
    border-radius: 12px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    margin-bottom: 30px;
}
.pricing-table .pricing-plan-title {
    padding-top: 17px;
}
.pricing-table .pricing-plan-title .pricing-table-icon {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top right;
}
.pricing-table .pricing-plan-title .pricing-table-icon img {
    display: block;
    margin: 0 auto;
}
.pricing-table .pricing-plan-title .pricing-table-icon .plan-title {
    margin-bottom: 23px;
    color: #fff;
    padding: 10px 25px;
    background: #F4732A;
    display: inline-block;
    border-radius: 50px;
    font-size: 17px;
    font-family: 'CircularStdBold';
    -webkit-box-shadow: 0px 7px 25px 0px rgb(255 78 0 / 40%);
            box-shadow: 0px 7px 25px 0px rgb(255 78 0 / 40%);
    line-height: 1;
}
.pricing-table .pricing-plan-title .pricing-table-icon .plan-title.bg-burning-orange-gradient {
    background-image: -ms-linear-gradient( -38deg, rgb(255,78,0) 0%, rgb(255,114,0) 59%, rgb(254,149,0) 100%);
    -webkit-box-shadow: 0px 7px 25px 0px rgba(255, 78, 0, 0.4);
            box-shadow: 0px 7px 25px 0px rgba(255, 78, 0, 0.4);
}
.pricing-table .pricing-plan-title .pricing-table-icon .plan-title.bg-ocean-blue-gradient {
    background-image: linear-gradient( -38deg, rgb(0,64,229) 0%, rgb(2,140,235) 59%, rgb(4,215,241) 100%);
    background-image: -webkit-linear-gradient( -38deg, rgb(0,64,229) 0%, rgb(2,140,235) 59%, rgb(4,215,241) 100%);
    background-image: -ms-linear-gradient( -38deg, rgb(0,64,229) 0%, rgb(2,140,235) 59%, rgb(4,215,241) 100%);
    -webkit-box-shadow: 0px 7px 25px 0px rgba(0, 64, 229, 0.4);
            box-shadow: 0px 7px 25px 0px rgba(0, 64, 229, 0.4);
} 
.pricing-table .pricing-plan-title .pricing-table-icon .plan-title.bg-magenta-gradient {
    background-image: -ms-linear-gradient( -38deg, rgb(120,57,243) 0%, rgb(179,29,225) 59%, rgb(237,0,206) 100%);
    -webkit-box-shadow: 0px 7px 25px 0px rgba(237, 0, 206, 0.4);
            box-shadow: 0px 7px 25px 0px rgba(237, 0, 206, 0.4);
}
.pricing-table .pricing-plan-cost {
    margin-top: 42px;
    margin-bottom: 25px;
}
.pricing-table .pricing-plan-cost .plan-price {
    font-size: 65px;
    margin-right: 10px;
    line-height: 1;
}
.pricing-table .pricing-plan-cost .plan-type {
    color: #606060;
    font-size: 17px;
}
.pricing-table .pricing-plan-cost .payment-currency {
    color: #ff4e00;
    font-size: 24px;
    top: -25px;
    position: relative;
}
.pricing-table .pricing-plan-features ul li {
    color: #606060;
    font-size: 17px;
    position: relative;
    margin-bottom: 9px;
    margin-left:25px;
}
.pricing-table .pricing-plan-features ul li:before {
    position: absolute;
    content: "";
    font-family: "Font Awesome 5 Pro";
    font-size: 9px;
    background-color: #ff4e00;
    color: #fff;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    top: 7.5px;
    left:-20px;
    line-height: 15px;
        text-align: left;
}
.pricing-table .pricing-plan-features ul li.plan-feature.plan-feature-disabled:after {
    background-color: #B2C5F7;
}
.pricing-table .pricing-plan-features {
    margin-bottom: 37px;
}
.pricing-table.professional-plan .pricing-plan-cost .payment-currency {
    color: #0040e5;
}
.pricing-table.professional-plan{
    position: relative;
    border-color: transparent;
    z-index: 1;
}
.pricing-table.professional-plan::before {
    content:"";
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    border-radius:12px; 
    padding:2px; 
    background: -o-linear-gradient( 128deg, rgb(4,215,241) 0%, rgb(2,140,235) 59%, rgb(0,64,229) 100%); 
    background: linear-gradient( -38deg, rgb(4,215,241) 0%, rgb(2,140,235) 59%, rgb(0,64,229) 100%); 
    mask: linear-gradient( -38deg, rgb(4,215,241) 0%, rgb(2,140,235) 59%, rgb(0,64,229) 100%) content-box, 
    linear-gradient( -38deg, rgb(4,215,241) 0%, rgb(2,140,235) 59%, rgb(0,64,229) 100%);
    -webkit-mask: linear-gradient( -38deg, rgb(4,215,241) 0%, rgb(2,140,235) 59%, rgb(0,64,229) 100%) content-box, 
    linear-gradient( -38deg, rgb(4,215,241) 0%, rgb(2,140,235) 59%, rgb(0,64,229) 100%);
    -webkit-mask-composite: destination-out; 
    mask-composite: exclude; 
    z-index: -1;
    transition: 0.3s;
}
.pricing-table.premium-plan .pricing-plan-cost .payment-currency {
    color: #ff4e00;
}
.pricing-table.professional-plan .pricing-plan-features ul li:after {
    background-color: #0040e5;
}
.pricing-table.premium-plan .pricing-plan-features ul li:after {
    background: #ed00ce;
}
.pricing-table.starter-plan:hover {
    border-color: #f4732a;
    -webkit-box-shadow: 0px 10px 30px 0px rgb(244 115 42 / 18%);
    box-shadow: 0px 10px 30px 0px rgb(244 115 42 / 18%);
}
.pricing-table.professional-plan:hover {
    -webkit-box-shadow: 0px 7px 25px 0px rgb(0 64 229 / 18%);
    box-shadow: 0px 7px 25px 0px rgb(0 64 229 / 18%);
    border-color: #0040e5;
}
.pricing-table.professional-plan:hover:before {
    opacity: 0;
}
.pricing-table.premium-plan:hover {
    border-color: #ed00ce;
    -webkit-box-shadow: 0px 10px 30px 0px rgb(237 0 206 / 18%);
    box-shadow: 0px 10px 30px 0px rgb(237 0 206 / 18%);
}

/* Pricing Table V2 */
.pricing-area .pricing-area-content .section-title h2 {
    margin-bottom: 60px;
}
.pricing-area .pricing-area-content .section-title .section-title-description{
    margin-bottom: 46px;
}
.pricing-table.pricing-table-v2:hover{
    border-color: #fff;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(206, 189, 224, 0.36);
            box-shadow: 0px 10px 30px 0px rgba(206, 189, 224, 0.36);
}
.pricing-table.pricing-table-v2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 30px;
}
.pricing-table.pricing-table-v2 .pricing-plan-cost .plan-price {
    font-size: 60px;
}
.pricing-table.pricing-table-v2 .pricing-plan-title {
    width: 50%;
}
.pricing-table.pricing-table-v2 .pricing-table-foot {
    width: 50%;
}
.pricing-table.pricing-table-v2 .pricing-plan-title {
    padding-top: 0;
}
.pricing-table.pricing-table-v2  .pricing-plan-cost {
    margin-top: 0;
    margin-bottom: 23px;
}
.pricing-table.pricing-table-v2 .pricing-plan-features {
    margin-bottom: 0;
}
.pricing-table.pricing-table-v2 {
    padding: 42px 49px 31px 49px;
    margin-bottom: 20px;
}
.pricing-table.pricing-table-v2 .plan-price {
    color: #36234b;
}
.pricing-table.pricing-table-v2  .pricing-plan-title .pricing-table-icon .plan-title {
    margin-bottom: 30px;
}


/* 16. Features Area */

/* Single Iconic Box */
.single-iconic-box{
    margin-bottom: 40px;
}
.single-iconic-box .iconic-box-icon {
    height: 60px;
    width: 60px;
    text-align: center;
    line-height: 60px;
    background-image: linear-gradient( -38deg, rgb(120,57,243) 0%, rgb(179,29,225) 59%, rgb(237,0,206) 100%);
    box-shadow: 0px 10px 60px 0px rgba(218, 9, 212, 0.28);
    border-radius: 50%;
    font-size: 30px;
    color: #fff;
    margin-bottom: 27px;
}
.single-iconic-box  .iconic-box-icon.iconic-box-gradient-2 {
    background-image: linear-gradient( -38deg, rgb(255,146,0) 0%, rgb(246,73,103) 59%, rgb(237,0,206) 100%);
    box-shadow: 0px 10px 60px 0px rgba(253, 132, 19, 0.28);
}
.single-iconic-box .iconic-box-icon.iconic-box-gradient-3 {
    background-image: linear-gradient( -38deg, rgb(243,57,70) 0%, rgb(200,29,154) 59%, rgb(156,0,237) 100%);
    box-shadow: 0px 10px 60px 0px rgba(218, 9, 212, 0.28);
}
.single-iconic-box .iconic-box-icon.iconic-box-gradient-4 {
    background-image: linear-gradient( -38deg, rgb(70,57,243) 0%, rgb(113,29,240) 59%, rgb(156,0,237) 100%);
    box-shadow: 0px 10px 60px 0px rgba(84, 54, 240, 0.28);
}
.single-iconic-box .iconic-box-icon.iconic-box-gradient-5 {
    background-image: linear-gradient( -38deg, rgb(31,93,251) 0%, rgb(16,165,207) 59%, rgb(0,237,162) 100%);
    box-shadow: 0px 10px 60px 0px rgba(33, 113, 238, 0.28);
}
.single-iconic-box .iconic-box-icon.iconic-box-gradient-6 {
    background-image: linear-gradient( -38deg, rgb(253,186,26) 0%, rgb(245,93,100) 59%, rgb(237,0,173) 100%);
    box-shadow: 0px 10px 60px 0px rgba(218, 9, 212, 0.28);
}
.single-iconic-box .iconic-box-body .iconic-box-title {
    color: #36234b;
    font-size: 22px;
    margin-bottom: 7px;
    line-height: 30px;
}
.single-iconic-box .iconic-box-body .iconic-box-content {
    font-size: 17px;
    line-height: 30px;
    color: #6f667b;
    margin-bottom: 16px;
}
.single-iconic-box .iconic-box-body .iconic-box-btn a {
    color: #6f667b;
    font-family: 'CircularStdBold';
    text-transform: capitalize;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    position: relative;
    z-index: 1;
    display: inline-block;
}
.single-iconic-box .iconic-box-body .iconic-box-btn a i {
    padding-left: 8px;
}
.single-iconic-box .iconic-box-body .iconic-box-btn a:after {
    position: absolute;
    content: "";
    height: 2px;
    width: 100%;
    background-color: #9C92A9;
    left: 0;
    bottom: -3px;
    z-index: -1;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
.single-iconic-box .iconic-box-body .iconic-box-btn a:hover, .single-iconic-box:hover .iconic-box-body .iconic-box-btn a {
    color: #36234b;
}
.single-iconic-box .iconic-box-body .iconic-box-btn a:hover:after, .single-iconic-box:hover .iconic-box-body .iconic-box-btn a:after {
    background-color: #36234b;
}

/* Iconic Box V2 */
.icon-boxes-wrapper.icon-boxes-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.icon-boxes-wrapper.icon-boxes-grid .single-iconic-box.iconic-box-v2 {
    width: 30%;
    border: 1px solid #ddd;
}
/* .icon-boxes-wrapper.icon-boxes-grid .single-iconic-box.iconic-box-v2:nth-child(1), .icon-boxes-wrapper.icon-boxes-grid .single-iconic-box.iconic-box-v2:nth-child(3) {
    //border-right: 0;
}
.icon-boxes-wrapper.icon-boxes-grid .single-iconic-box.iconic-box-v2:nth-child(1), .icon-boxes-wrapper.icon-boxes-grid .single-iconic-box.iconic-box-v2:nth-child(2) {
    //border-bottom: 0;
} */
.single-iconic-box.iconic-box-v2 {
    padding: 38px 35px;
    -webkit-transition: all .3s linear;
    -o-transition: all .3s linear;
    transition: all .3s linear;
}
.single-iconic-box.iconic-box-v2 .iconic-box-content {
    margin-bottom: 0;
}
.single-iconic-box.iconic-box-v2:hover {
    background: #fff;
    -webkit-box-shadow: 0px 10px 60px 0px rgba(189, 189, 189, 0.3);
            box-shadow: 0px 10px 60px 0px rgba(189, 189, 189, 0.3);
}
.icon-boxes-wrapper.icon-boxes-grid .single-iconic-box.iconic-box-v2 {
    position: relative;
    z-index: 2;
    margin-bottom: 0;
}
.icon-boxes-wrapper.icon-boxes-grid .single-iconic-box.iconic-box-v2:before {
    content: "";
    height: 100%;
    width: 100%;
    background-color: #fff;
    left: 0;
    top: 0;
    z-index: -1;
    position: absolute;
    border-radius: 10px;
    -webkit-transition: all .3s linear;
    -o-transition: all .3s linear;
    transition: all .3s linear;
    -webkit-box-shadow: 0px 10px 60px 0px rgb(189 189 189 / 30%);
            box-shadow: 0px 10px 60px 0px rgb(189 189 189 / 30%);
    visibility: hidden;
    opacity: 0;
    -webkit-transform: rotateY(-70deg);
    -ms-transform: rotateY(-70deg);
    transform: rotateY(-70deg);
    -webkit-transform-origin: left;
    -ms-transform-origin: left;
    transform-origin: left;
}
.icon-boxes-wrapper.icon-boxes-grid .single-iconic-box.iconic-box-v2:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    z-index: -2;
    background-color: #473BF0;
    top: 0;
    left: 0;
    border-radius: 10px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .3s linear;
    -o-transition: all .3s linear;
    transition: all .3s linear;
    opacity: 0;
    -webkit-transform: rotateY(-70deg);
    -ms-transform: rotateY(-70deg);
    transform: rotateY(-70deg);
    -webkit-transform-origin: left;
    -ms-transform-origin: left;
    transform-origin: left;
}
.icon-boxes-wrapper.icon-boxes-grid .single-iconic-box.iconic-box-v2:hover:before {
    opacity: 1;
    right: -5px;
    left: auto;
    top: 5px;
    visibility: visible;
    -webkit-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
}
.icon-boxes-wrapper.icon-boxes-grid .single-iconic-box.iconic-box-v2:hover:after {
    opacity: 1;
    top: -5px;
    left: -5px;
    visibility: visible;
    -webkit-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
}
.icon-boxes-wrapper.icon-boxes-grid .single-iconic-box.iconic-box-v2:hover {
    border-color: transparent;
}
.icon-boxes-wrapper.icon-boxes-grid .single-iconic-box:hover + .single-iconic-box.iconic-box-v2:nth-child(2) {
    border-left-color: transparent;
}
.icon-boxes-wrapper.icon-boxes-grid .single-iconic-box:hover ~ .single-iconic-box.iconic-box-v2:nth-child(3) {
    border-top-color: transparent;
}
.icon-boxes-wrapper.icon-boxes-grid .single-iconic-box:nth-child(2):hover ~ .single-iconic-box.iconic-box-v2:nth-child(3) {
    border-top-color: #E2DDE4;
}
.icon-boxes-wrapper.icon-boxes-grid .single-iconic-box:nth-child(2):hover ~ .single-iconic-box.iconic-box-v2:nth-child(4) {
    border-top-color: transparent;
}
.icon-boxes-wrapper.icon-boxes-grid .single-iconic-box:nth-child(3):hover ~ .single-iconic-box.iconic-box-v2:nth-child(4) {
    border-left-color: transparent;
}

/* Iconic Box V3 */
.single-iconic-box.iconic-box-v3 .iconic-box-icon {
    height: auto;
    width: auto;
    background-image: none;
    -webkit-box-shadow: none;
            box-shadow: none;
    text-align: left;
    max-width: 60px;
    margin-bottom: 33px;
}
.single-iconic-box.iconic-box-v3 .iconic-box-body .iconic-box-title {
    color: #161c2d;
}
.single-iconic-box.iconic-box-v3 .iconic-box-body  .iconic-box-content {
    color: #6e727d;
    margin-bottom: 25px;
}
.single-iconic-box.iconic-box-v3 .iconic-box-body .iconic-box-btn a:after {
    height: 15px;
    width: 15px;
    border-radius: 50px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    background-color: #1D79FB;
    z-index: -1;
    bottom: 0;
    left: 50%;
    margin-left: -7.5px;
    top: 50%;
    margin-top: -7.5px;
    opacity: 0;
}
.single-iconic-box.iconic-box-v3 .iconic-box-body .iconic-box-btn a i {
    padding: 0;
}
.single-iconic-box.iconic-box-v3 .iconic-box-body .iconic-box-btn a {
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 50px;
    border: 1px solid #1D79FB;
    border-radius: 50px;
    color: #1D79FB;
}
.single-iconic-box.iconic-box-v3 .iconic-box-body .iconic-box-btn a {
    overflow: hidden;
    z-index: 1;
}
.single-iconic-box.iconic-box-v3 .iconic-box-body .iconic-box-btn a:hover:after, .single-iconic-box.iconic-box-v3:hover .iconic-box-body .iconic-box-btn a:after {
    height: 50px;
    width: 50px;
    top: 0;
    left: 0;
    margin: 0;
    opacity: 1;
}

.single-iconic-box.iconic-box-v3 .iconic-box-body .iconic-box-btn a:hover,
.single-iconic-box.iconic-box-v3:hover .iconic-box-body .iconic-box-btn a {
    color: #fff;
}
.single-iconic-box.iconic-box-v3.exclusive-iconic-box-1 .iconic-box-body .iconic-box-btn a {
    color: #19CB55;
    border: 1px solid rgb(25 203 85 / 25%);
}
.single-iconic-box.iconic-box-v3.exclusive-iconic-box-1:hover .iconic-box-body .iconic-box-btn a {
    color: #fff;
    border: 1px solid #19CB55;
}
.single-iconic-box.iconic-box-v3.exclusive-iconic-box-1 .iconic-box-body .iconic-box-btn a:hover {
    border: 1px solid #19CB55;
    color: #fff;
}
.single-iconic-box.iconic-box-v3.exclusive-iconic-box-1 .iconic-box-body .iconic-box-btn a:hover:after, .single-iconic-box.iconic-box-v3.exclusive-iconic-box-1:hover .iconic-box-body .iconic-box-btn a:after {
    background-color: #19CB55;
}
.single-iconic-box.iconic-box-v3.exclusive-iconic-box-3 .iconic-box-body .iconic-box-btn a {
    border: 1px solid rgb(249 95 117 / 25%);
    color: #f95f75;
}
.single-iconic-box.iconic-box-v3.exclusive-iconic-box-3 .iconic-box-body .iconic-box-btn a:after {
    background-color: #f95f75;
}
.single-iconic-box.iconic-box-v3.exclusive-iconic-box-3 .iconic-box-body .iconic-box-btn a:hover, .single-iconic-box.iconic-box-v3.exclusive-iconic-box-3:hover .iconic-box-body .iconic-box-btn a {
    color: #fff;
    border: 1px solid #f95f75;
}
.single-iconic-box.iconic-box-v3.exclusive-iconic-box-4 .iconic-box-body .iconic-box-btn a {
    color: #ff8500;
    border: 1px solid rgb(255 133 0 / 25%);
}
.single-iconic-box.iconic-box-v3.exclusive-iconic-box-4 .iconic-box-body .iconic-box-btn a:after {
    background-color: #ff8500;
}
.single-iconic-box.iconic-box-v3.exclusive-iconic-box-4 .iconic-box-body .iconic-box-btn a:hover, .single-iconic-box.iconic-box-v3.exclusive-iconic-box-4:hover .iconic-box-body .iconic-box-btn a {
    color: #fff;
    border: 1px solid #ff8500;
}

/* Iconic Box V4 */
.single-iconic-box.iconic-box-v4 {
    -webkit-box-shadow: 0px 10px 60px 0px rgba(189, 189, 189, 0.3);
            box-shadow: 0px 10px 60px 0px rgba(189, 189, 189, 0.3);
    border-radius: 10px;
    padding: 28px;
}
.single-iconic-box.iconic-box-v4 .iconic-box-body .iconic-box-title {
    color: #161c2d;
}
.single-iconic-box.iconic-box-v4 .iconic-box-body .iconic-box-content {
    color: #6e727d;
}
.single-iconic-box.iconic-box-v4 .iconic-box-content {
    margin-bottom: 0;
}

/* Iconic Box V5 */
.single-iconic-box.iconic-box-v5 {
    margin-bottom: 60px;
}
.single-iconic-box.iconic-box-v5 .iconic-box-icon {
    background-image: none;
    box-shadow: none;
    background-color: transparent;
    height: auto;
    width: auto;
    text-align: left;
    margin-bottom: 25px;
}
.single-iconic-box.iconic-box-v5 .iconic-box-title {
    font-size: 20px;
    color: #1f1f1f;
}
.single-iconic-box.iconic-box-v5 .iconic-box-content {
    color: #7a7a7a;
    margin-bottom: 0;
}

/* Simple Icon Box */
.simple-icon-box-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.simple-single-icon-box h4 {
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 6px;
}
.simple-single-icon-box p {
    color: #6e727d;
}
.simple-single-icon-box i {
    margin-bottom: 22px;
    font-size: 40px;
    color: #473bf0;
}
/* Simple Icon Box V2 */
.simple-single-icon-box.simple-single-icon-box-v2 i {
    margin-bottom: 13px;
}
.simple-single-icon-box.simple-single-icon-box-v2 h4 {
    font-size: 20px;
    line-height: 24px;
}

/* 17. Manage Data Area Iconic List */

/* Single Iconic List */
.single-iconic-list.iconic-list-bg {
    background-color: #fff;
    border-radius: 15px;
    -webkit-box-shadow: 0px 10px 60px 0px rgb(189 189 189 / 30%);
            box-shadow: 0px 10px 60px 0px rgb(189 189 189 / 30%);
}
.single-iconic-list.iconic-list-padding {
    padding: 35px;
}
.single-iconic-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.single-iconic-list .iconic-list-icon {
    max-width: 30px;
    min-width: 30px;
    margin-right: 20px;
}
.single-iconic-list .iconic-list-icon i {
    background-color: #473BF0;
    font-size: 15px;
    height: 30px;
    width: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 50px;
    color: #fff;
}
.single-iconic-list .iconic-list-body .iconic-list-title {
    font-size: 22px;
    color: #36234b;
    line-height: 30px;
    margin-bottom: 7px;
    text-transform: capitalize;
}
.single-iconic-list .iconic-list-body .iconic-list-content {
    font-size: 17px;
    color: #6f667b;
    line-height: 30px;
}
.iconic-list-wrapper .single-iconic-list:last-child {
    margin-bottom: 0;
    margin-top: 5px;
}



/* Contact Info Box */
.info-iconic-box {
    -webkit-box-shadow: 0px 0px 30px 0px rgba(234, 234, 234, 0.5);
            box-shadow: 0px 0px 30px 0px rgba(234, 234, 234, 0.5);
    margin-bottom: 75px;
    padding: 30px 35px;
    text-align: center;
    border-radius: 5px;
}
.info-iconic-boxes .info-iconic-box:last-child {
    margin-bottom: 0;
}
.info-iconic-box .info-icon i {
    font-size: 40px;
    line-height: 90px;
    color: #fff;
}
.info-iconic-box .info-icon {
    height: 90px;
    width: 90px;
    text-align: center;
    margin: 0 auto;
    border-radius: 50px;
    background-image: linear-gradient( -38deg, rgb(243,57,70) 0%, rgb(200,29,154) 59%, rgb(156,0,237) 100%);
    background-image: -webkit-linear-gradient( -38deg, rgb(243,57,70) 0%, rgb(200,29,154) 59%, rgb(156,0,237) 100%);
    background-image: -ms-linear-gradient( -38deg, rgb(243,57,70) 0%, rgb(200,29,154) 59%, rgb(156,0,237) 100%);
    -webkit-box-shadow: 0px 10px 60px 0px rgba(218, 9, 212, 0.28);
            box-shadow: 0px 10px 60px 0px rgba(218, 9, 212, 0.28);
    margin-bottom: 35px;
    margin-top: -75px;
}
.info-iconic-box .info-body h5 {
    font-size: 24px;
    margin-bottom: 10px;
    text-transform: capitalize;
}
.info-iconic-box .info-body  p {
    color: #6e727d;
    font-family: 'CircularStdMedium';
}
.info-iconic-box .info-body a {
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
.info-iconic-box .info-body a:hover {
    color: #161c2d;
}
.info-iconic-box .info-icon.info-icon-gradient-1 {
    background-image: linear-gradient( -38deg, rgb(243,57,70) 0%, rgb(200,29,154) 59%, rgb(156,0,237) 100%);
    background-image: -webkit-linear-gradient( -38deg, rgb(243,57,70) 0%, rgb(200,29,154) 59%, rgb(156,0,237) 100%);
    background-image: -ms-linear-gradient( -38deg, rgb(243,57,70) 0%, rgb(200,29,154) 59%, rgb(156,0,237) 100%);
    box-shadow: 0px 10px 60px 0px rgba(218, 9, 212, 0.28);
}
.info-iconic-box .info-icon.info-icon-gradient-2 {
    background-image: linear-gradient( -38deg, rgb(70,57,243) 0%, rgb(113,29,240) 59%, rgb(156,0,237) 100%);
    background-image: -webkit-linear-gradient( -38deg, rgb(70,57,243) 0%, rgb(113,29,240) 59%, rgb(156,0,237) 100%);
    background-image: -ms-linear-gradient( -38deg, rgb(70,57,243) 0%, rgb(113,29,240) 59%, rgb(156,0,237) 100%);
    box-shadow: 0px 10px 60px 0px rgba(84, 54, 240, 0.28);
}
.info-iconic-box .info-icon.info-icon-gradient-3 {
    background-image: linear-gradient( -38deg, rgb(253,186,26) 0%, rgb(245,93,100) 59%, rgb(237,0,173) 100%);
    background-image: -webkit-linear-gradient( -38deg, rgb(253,186,26) 0%, rgb(245,93,100) 59%, rgb(237,0,173) 100%);
    background-image: -ms-linear-gradient( -38deg, rgb(253,186,26) 0%, rgb(245,93,100) 59%, rgb(237,0,173) 100%);
    box-shadow: 0px 10px 60px 0px rgba(218, 9, 212, 0.28);
}

/* Contact Box (Home V5) */
.single-contact-box h4 {
    font-size: 24px;
    color: #161c2d;
    line-height: 32px;
    margin-bottom: 15px;
}
.single-contact-box {
    background-color: #fff;
    -webkit-box-shadow: 0px 10px 45px 0px rgba(71, 59, 240, 0.1);
            box-shadow: 0px 10px 45px 0px rgba(71, 59, 240, 0.1);
    padding: 40px 56px 43px 56px;
    border-radius: 7px;
    margin-bottom: 25px;
}
.single-contact-box h4 span {
    color: #473bf0;
}
.single-contact-box:hover .filled-btn:after,
.single-contact-box.single-contact-box-active .filled-btn:after {
    width: 100%;
}
.single-contact-box:hover .filled-btn,
.single-contact-box.single-contact-box-active .filled-btn{
    color: #fff;
    border-color: #161c2d;
    background-color: #161c2d;
    -webkit-transition-delay: 0.1s;
    -o-transition-delay: 0.1s;
    transition-delay: 0.1s;
    box-shadow: none;
}
.single-contact-box.single-contact-box-active .filled-btn:hover {
    background-color: #473BF0;
    border-color: #473BF0;
    -webkit-transition-delay: 0.1s;
    -o-transition-delay: 0.1s;
    transition-delay: 0.1s;
}
.single-contact-box.single-contact-box-active:hover .filled-btn {
    background-color: transparent;
    border-color: rgb(71 59 240 / 10%);
    color: #161c2d;
}
.single-contact-box.single-contact-box-active:hover .filled-btn:after {
    width: 0;
}

/* Single Info Box (Home V5) */
.our-services.our-services-v2 .section-title h2,
.company-statistic-area .section-title h2 {
    margin-bottom: 22px;
}
.single-info-box {
    margin-bottom: 30px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
.single-info-box:hover {
    -webkit-box-shadow: 0px 10px 45px 0px rgba(71, 59, 240, 0.1);
            box-shadow: 0px 10px 45px 0px rgba(71, 59, 240, 0.1);
}
.single-info-box .infobox-thumbnail a {
    display: block;
}
.single-info-box .infobox-thumbnail img {
    border-radius: 7px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    width: 100%;
}
.single-info-box  .infobox-body {
    background-color: #fff;
    padding: 21px 26px 28px;
}
.single-info-box .infobox-body .infobox-title {
    font-size: 18px;
    color: #161c2d;
    margin-bottom: 4px;
}
.single-info-box .infobox-body .infobox-title a:hover {
    color: #473bf0;
}
.single-info-box .infobox-body .infobox-title a {
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
.single-info-box .infobox-body .infobox-excerpt {
    color: #7a7a7a;
    font-size: 17px;
    margin-bottom: 4px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
.single-info-box .infobox-body .infobox-btn a {
    color: #7a7a7a;
    font-size: 15px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    display: inline-block;
}
.single-info-box .infobox-body .infobox-btn a i {
    margin-left: 10px;
}
.single-info-box .infobox-body .infobox-btn a {
    position: relative;
}
.single-info-box .infobox-body .infobox-btn a:after {
    position: absolute;
    content: "";
    height: 2px;
    width: 0;
    background-color: #7a7a7a;
    left: 0;
    bottom: 0;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
.single-info-box:hover .infobox-body .infobox-btn a:after {
    width: 100%;
    background-color: #473BF0;
}
.single-info-box:hover .infobox-body .infobox-btn a {
    color: #473BF0;
}
.single-info-box:hover .infobox-body .infobox-excerpt {
    height: auto;
}
.single-info-box .infobox-thumbnail {
    position: relative;
    overflow: hidden;
}
.single-info-box .infobox-thumbnail:before {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    display: block;
    content: "";
    width: 0px;
    height: 0px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 0;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 0px;
}
.single-info-box:hover .infobox-thumbnail:before {
    -webkit-animation-duration: 0.95s;
    animation-duration: 0.95s;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-direction: normal;
    animation-direction: normal;
    -webkit-animation-fill-mode: none;
    animation-fill-mode: none;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    -webkit-animation-name: circle;
    animation-name: circle;
}
.single-info-box:hover .infobox-thumbnail img {
    transform: scale(1.2);
}

/* Our Services Area V3 (Home V6) */
.our-services.our-services-v3 .section-title h2 {
    margin-bottom: 11px;
}
.our-services.our-services-v3  .section-title .section-title-description p {
    color: #7a7a7a;
}
/* Services Law Area (Home V6) */
.services-law-images {
    position: relative;
}
.services-law-images .services-law-image-main {
    margin-top: 50px;
    margin-bottom: 50px;
}
.services-law-images .services-law-image-main img {
    border-radius: 7px;
    width: 100%;
}
.services-law-images .services-law-image-relative, 
.services-law-images .services-law-image-absolute {
    position: absolute;
}
.services-law-images .services-law-image-relative {
    right: 0px;
    top: -50px;
}
.services-law-images .services-law-image-absolute {
    left: 0;
    bottom: -50px;
    -webkit-box-shadow: 0px 10px 60px 0px rgb(51 119 255 / 15%);
    box-shadow: 0px 10px 60px 0px rgb(51 119 255 / 15%);
}
/* Contact Form Area */
.contact-respond .form-control:focus {
    outline: 0;
    border-color: #EFF0F2;
    -webkit-box-shadow: none;
            box-shadow: none;
}
.contact-respond .form-control {
    border: 1px solid #EFF0F2;
    height: 55px;
    padding: 0 30px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    font-size: 18px;
    color: #1b2336;
    background-color: #F2F1FE;
    border-radius: 7px;
    border: 2px solid #F2F1FE;
}
.contact-respond .input-group {
    margin-bottom: 30px;
}
.contact-respond .input-group:last-child{
    margin-bottom: 0;
}
.contact-respond textarea.form-control {
    padding: 15px 30px;
    height: 250px;
}
.contact-respond input::-webkit-input-placeholder, .contact-respond textarea::-webkit-input-placeholder{
    font-size: 16px;
}
.contact-respond input::placeholder, .contact-respond textarea::placeholder{
    font-size: 16px;
}
.contact-respond input:-ms-input-placeholder, .contact-respond textarea:-ms-input-placeholder{
    font-size: 16px;
}
.contact-respond input::-ms-input-placeholder, .contact-respond textarea::-ms-input-placeholder{
    font-size: 16px;
}
.contact-respond input::placeholder,
.contact-respond textarea::placeholder{
    font-size: 16px;
}
.contact-respond input::-webkit-input-placeholder, .contact-respond textarea::-webkit-input-placeholder{
    font-weight: 400;
    color: #6e727d;
}
.contact-respond input::placeholder, .contact-respond textarea::placeholder{
    font-weight: 400;
    color: #6e727d;
}
.contact-respond input:-ms-input-placeholder, .contact-respond textarea:-ms-input-placeholder{
    font-weight: 400;
    color: #6e727d;
}
.contact-respond input::-ms-input-placeholder, .contact-respond textarea::-ms-input-placeholder{
    font-weight: 400;
    color: #6e727d;
}
.contact-respond input::placeholder,
.contact-respond textarea::placeholder{
    font-weight: 400;
    color: #6e727d;
}


.contact-respond input:focus::-webkit-input-placeholder{
    color: transparent;
}
.contact-respond input:focus::placeholder{
    color: transparent;
}
.contact-respond input:focus:-ms-input-placeholder{
    color: transparent;
}
.contact-respond input:focus::-ms-input-placeholder{
    color: transparent;
}
.contact-respond input:focus::placeholder{
    color: transparent;
}

.contact-respond .input-group.form-textarea .form-control:focus {
    padding-left: 30px;
}
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
}
.contact-respond .subject-dropdown {
    width: 100%;
}
.contact-respond .subject-dropdown .nice-select.open:after {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    top: 13px;
    content: "\f106";
}
.contact-respond .subject-dropdown .nice-select:after {
    color: #473BF0;
}
.contact-respond .nice-select {
    width: 100%;
    height: 60px;
    padding: 0 30px;
    float: none;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0 30px;
    background-color: #F4F7FA;
    border: 0;
    font-weight: 400;
    color: #6e727d;
    font-size: 16px;
}
.contact-respond .nice-select ul.list {
    width: 100%;
    border-radius: 7px;
    margin-top: 5px;
    box-shadow: 0 0 0 1px #f4f7fa;
}
.contact-respond .nice-select ul.list li {
    font-size: 16px;
    color: #1b2336;
    font-weight: 500;
}
.contact-respond .nice-select ul.list li.selected.focus, .contact-respond .nice-select ul.list li:hover {
    background: #f4f7fa;
}
.contact-respond .nice-select ul.list li {
    padding: 5px 30px;
}

/* Contact Form Area V2 (Home V5) */
.contact-form-area.contact-form-area-v2 .contact-respond .input-group {
    margin-bottom: 15px;
    
}

.contact-form-area.contact-form-area-v2 .contact-respond .input-group1 {
    margin: 0px 0px 0px 5px;
}

.contact-form-area.contact-form-area-v2 .contact-respond .input-group:last-child {
    margin: 0;
}
.contact-form-area.contact-form-area-v2 .contact-respond .form-control {
    background-color: #F4F7FA;
    border-color: #F4F7FA;
}
.contact-form-area.contact-form-area-v2 .contact-respond textarea.form-control {
    height: 165px;
}
.contact-form-area.contact-form-area-v2 .contact-respond {
    padding: 28px 25px;
    border: 1px solid #EFEFF0;
    border-radius: 7px;
}


/* Contact Page Maps */
.map-box iframe {
    height: 670px;
    width: 100%;
    border: 0;
}

/* 25. 404 Not Found Area */
.error-page-content {
    margin-top: 80px;
}
.error-page-content h2 {
    margin-bottom: 20px;
    font-size: 85px;
    line-height: 1;
}
.error-page-content p {
    margin-bottom: 33px;
    font-size: 30px;
    font-family: 'CircularStdMedium';
    line-height: 40px;
    color: #161c2d;
}

/* 30. Footer Area */
.footer-area {
    border-top: 1px solid rgb(71 59 240 / 10%);
    border-bottom: 5px solid #473BF0;
}
.footer-area.bg-white-lilac {
    background-color: #F4F7FA;
}
.footer-area .footer-area-internal{
    padding-top: 76px;
    padding-bottom: 40px;
}
.footer-area .widget-title {
    margin-bottom: 28px;
}
.single-footer-widget{
    margin-bottom: 30px;
}
.single-footer-widget.footer-text-widget img {
    margin-bottom: 15px;
}
.single-footer-widget.footer-text-widget p {
    color: #6e727d;
    font-size: 17px;
    line-height: 32px;
    margin-bottom: 20px;
}
.footer-area .single-footer-widget.contact-us-widget .footer-widget-menu ul li a:after {
    display: none;
}
.footer-widget-menu ul li:last-child a{
    margin-bottom: 0;
}
.footer-area .single-footer-widget.contact-us-widget .footer-widget-menu ul li a {
    padding-left: 33px;
}
.footer-area .single-footer-widget.contact-us-widget .footer-widget-menu ul li a i {
    position: absolute;
    left: 0;
    top: 7px;
}
.footer-contact-link {
    font-size: 18px;
    color: #161c2d;
    font-family: 'CircularStdMedium';
}
.footer-contact-link:hover{
    color: #473bf0;
}
.footer-widget-menu ul li a {
    margin-bottom: 10px;
    color: #6e727d;
    font-size: 17px;
    position: relative;
}
.footer-widget-menu ul li a:after {
    content: "\f054";
    position: absolute;
    left: 0;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 800;
    font-size: 14px;
    top: 0px;
}
.footer-widget-menu ul li a {
    padding-left: 20px;
}
.footer-widget-menu ul li a {
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
.footer-widget-menu ul li a:hover {
    color: #473bf0;
}
.footer-social-links ul li {
    display: inline-block;
}
.footer-social-links ul li a {
    display: block;
    height: 35px;
    width: 35px;
    background: #ECEBFD;
    border-radius: 50px;
    text-align: center;
    line-height: 36px;
    font-size: 14px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    margin-right: 8px;
}
.footer-social-links ul li a:hover {
    background: #473bf0;
    color: #fff;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(71, 59, 240, 0.36);
            box-shadow: 0px 10px 30px 0px rgba(71, 59, 240, 0.36);
}
.footer-social-links {
    margin-bottom: 30px;
}
.footer-copyright p {
    font-size: 17px;
    color: #6e727d;
    line-height: 32px;
	text-align:center;
}
.footer-copyright a {
    color: #473BF0;
}
.footer-social-links ul li:last-of-type a {
    margin-right: 0;
}
.footer-contact-link i {
    margin-right: 5px;
    color: #473BF0;
}
.footer-copyright-area {
    border-top: 1px solid #1A53E8;
    padding-top: 30px;
    padding-bottom: 30px;
}
.footer-copyright-area .footer-copyright{
    text-align: right;
}

.single-footer-widget .footer-app-download .filled-btn {
    margin-bottom: 10px;
}

/* Footer Area V4 (Home V6) */
.footer-area.footer-area-v4,
.footer-area.footer-area-v4 .footer-copyright-area {
    border: 0;
}
.footer-area.footer-area-v4 .footer-area-internal {
    border-bottom: 1px solid #394565;
}
.footer-area.footer-area-v4 .widget-title {
    color: #fff;
    margin-bottom: 23px;
}
.footer-area.footer-area-v4 .footer-widget-menu ul li a {
    color: #8496c6;
}
.footer-area.footer-area-v4 .single-footer-widget.footer-text-widget p {
    color: #8496c6;
}
.footer-area.footer-area-v4 .footer-contact-link {
    color: #8496c6;
    margin-bottom: 10px;
    font-family: 'CircularStdBook';
}
.footer-area.footer-area-v4 .footer-contact-link i {
    color: #8496c6;
}
.footer-area.footer-area-v4 .footer-copyright p {
    color: #8496c6;
}
.footer-area.footer-area-v4 .footer-copyright a {
    color: #8496c6;
}
.footer-area.footer-area-v4 .footer-copyright a:hover {
    color: #fff;
}
.footer-area.footer-area-v4 .footer-social-links ul li a {
    background: #3C4869;
    border: 2px solid #3C4869;
    color: #7285b7;
}
.footer-area.footer-area-v4 .footer-widget-menu ul li a i{
    color: #3377FF;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
.footer-area.footer-area-v4 .footer-social-links ul li a:hover {
    background-color: #3377FF;
    border-color: #3377FF;
    color: #fff;
    -webkit-box-shadow: 0px 10px 30px 0px rgb(51 119 255 / 36%);
    box-shadow: 0px 10px 30px 0px rgb(51 119 255 / 36%);
}
.footer-area.footer-area-v4 .footer-widget-menu ul li a:hover,
.footer-area.footer-area-v4 .footer-widget-menu ul li a:hover i {
    color: #fff;
}
.footer-area.footer-area-v4 .footer-copyright-area{
    padding: 26px 0 26px;
}
.footer-area.footer-area-v4 .footer-area-internal {
    padding-top: 75px;
    padding-bottom: 43px;
}

#spinneroverlay {
    background: #ccc;
    color: #666666;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 5000;
    top: 0;
    left: 0;
    float: left;
    text-align: center;
    padding-top: 25%;
    opacity: .40;
  }
  
  .spinner {
      margin: 0 auto;
      height: 64px;
      width: 64px;
      animation: rotate 0.8s infinite linear;
      border: 1px solid #244e50;
      border-right-color: transparent;
      border-radius: 50%;
  }
  @keyframes rotate {
      0% {
          transform: rotate(0deg);
      }
      100% {
          transform: rotate(360deg);
      }
  }
  /* Slider */
.slick-slider
{
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}
/* Slider */

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -25px;
    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}

   .pricing-box {
					background: #fff;
					padding: 20px 20px;
					text-align: left;
					margin-bottom: 30px;
							}
							
				.pricing-table .pricing-plan-cost .plan-price-strike {
					font-size: 35px;
					margin-right: 10px;
					line-height: 1;
					}
					
					.pricing-table .pricing-plan-title .pricing-table-icon {
    text-align: center;
}

				.pricing-table .pricing-plan-cost .plan-price {
					font-size: 45px !important;
					margin-right: 10px;
					line-height: 1;
					}

				.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}

.text-note{
	color:#c9c9c9;
}

   /* for desktop */
   .whatsapp_float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
    padding-left: 5px;
}

.whatsapp-icon {
    margin-top: 16px;
}

/* login registration css */
.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}
.h-custom-reg {
  height: calc(100% - 73px);
}

.bg-color-aqua
{
	background:#DAFBFF;
}


/* .single-contact-single-contact-box-reg {
    background-color: #fff;
    -webkit-single-contact-box-reg-shadow: 0px 10px 45px 0px rgb(71 59 240 / 10%);
    single-contact-box-reg-shadow: 0px 10px 45px 0px rgb(71 59 240 / 10%);
    padding: 30px 36px 30px 36px;
    border-radius: 7px;
    margin-bottom: 25px;
} */
.select-span{
	position: absolute;
	    z-index: 1;
    position: absolute;
    border: 0;
		
}
.bg-filed{
	background:#E8F0FE ;
    width: 100% !important;
}

.react-tel-input .bg-filed{
    width: 100% !important;
    background:#E8F0FE !important;
    min-height:calc(1.5em + 1rem + 2px)!important;
}